export const loginMailBotProfile = (serviceProvider: string, queryParams: Record<string, string> = {}) => {
    const queryString = Object.keys(queryParams)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join("&");
    if (serviceProvider === "google")
        window.open(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/mailbot/google-auth/login/?${queryString}`, "_self");
    else if (serviceProvider === "microsoft")
        window.open(
            `${process.env.REACT_APP_BACKEND_API_BASE_URL}/mailbot/microsoft-auth/login/?${queryString}`,
            "_self",
        );
};
