import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import BuySubscription from "components/Common/BuySubscription";
import ConfirmDialog from "components/Common/Dialog/ConfirmDialog";
import Loader from "components/Common/Loader";
import SnackBar from "components/Common/Snackbar";
import Preferences from "components/Home/Preferences";
import { maxContentWidthDesktop } from "defines";
import { useUser } from "hooks/api/accounts";
import {
    useActivateMailBotProfile,
    useDeactivateMailBotProfile,
    useDeleteUserMailBotProfile,
} from "hooks/api/mailbot/mutations";
import { useCanMailBotEnabled, useIsMailBotActive, useMailBot, useMailBotPreferences } from "hooks/api/mailbot/queries";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import moment from "moment-timezone";
import { useState } from "react";

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

export default function UserProfile() {
    const { data: userMailBotProfile, isPending } = useMailBot();
    const { data: user, isPending: isUserPending } = useUser();
    const deleteProfileMutation = useDeleteUserMailBotProfile();
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [showDeactivateConfirmDialog, setShowDeactivateConfirmDialog] = useState(false);
    const { data: mailbotPreferences, isPending: mailbotPreferencesPending } = useMailBotPreferences();
    const { data: canEnableMailBot, isPending: canEnableMailBotPending } = useCanMailBotEnabled();
    const { data: mailBotActive, isPending: mailBotActivePending } = useIsMailBotActive();
    const deactivateProfileMutation = useDeactivateMailBotProfile();
    const activateProfileMutation = useActivateMailBotProfile();
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const { md } = useCustomMediaQuery();
    const handleDeleteUser = () => {
        deleteProfileMutation.mutate(userMailBotProfile.id, {
            onSuccess: () => {
                setSuccessMessage("Your account has been deleted successfully.");
            },
            onError: () => {
                setErrorMessage("Error occurred while deleting your account. Please try again later.");
            },
        });
    };
    const handleDeactivateUser = () => {
        deactivateProfileMutation.mutate(
            { preferences: mailbotPreferences },
            {
                onSuccess: () => {
                    setSuccessMessage(
                        "Emailzap has been deactivated. All new Emails post deactivation will go to your inbox.",
                    );
                },
                onError: () => {
                    setErrorMessage("Error occurred while deactivating Emailzap. Please try again later.");
                },
            },
        );
    };

    const handleActivateUser = () => {
        activateProfileMutation.mutate(
            { preferences: mailbotPreferences },
            {
                onSuccess: () => {
                    setSuccessMessage("Emailzap has been activated successfully.");
                },
                onError: () => {
                    setErrorMessage("Error occurred while activating Emailzap. Please try again later.");
                },
            },
        );
    };
    if (
        isPending ||
        isUserPending ||
        mailBotActivePending ||
        deactivateProfileMutation.isPending ||
        activateProfileMutation.isPending ||
        deleteProfileMutation.isPending ||
        mailbotPreferencesPending ||
        subscriptionPending
    ) {
        return <Loader />;
    }
    return (
        <Box maxWidth={maxContentWidthDesktop} margin={"auto"}>
            <Box p={2}>
                <Typography fontWeight={600} variant={md ? "h6" : "subtitle1"}>
                    Profile
                </Typography>
                <Typography variant={md ? "body1" : "body2"} gutterBottom>
                    All the details related to profile
                </Typography>
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    marginTop={"12px"}
                    flexDirection={md ? "row" : "column"}
                >
                    <Box display={"flex"} alignItems={"center"} gap={"12px"}>
                        <Avatar
                            src={userMailBotProfile.user_picture}
                            sx={{
                                width: 66,
                                height: 66,
                            }}
                            imgProps={{
                                referrerPolicy: "no-referrer",
                            }}
                        />
                        <Box display="flex" flexDirection={"column"}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                {user.first_name} {user.last_name}
                            </Typography>
                            <Typography variant="caption">{user.email}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Button
                            disableElevation
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            onClick={() => {
                                if (mailBotActive && CURRENT_ENV !== "prod") {
                                    // Don't allow deactivate in production
                                    setShowDeactivateConfirmDialog(true);
                                } else {
                                    setShowDeleteConfirmDialog(true);
                                }
                            }}
                        >
                            <Typography fontWeight={600} color="warning" marginTop={md ? 0 : "16px"} variant="button">
                                Delete account
                            </Typography>
                        </Button>
                    </Box>
                </Box>
                <Box display={"flex"} alignItems={"baseline"} gap={"16px"} my={"16px"}>
                    <Typography fontWeight={600} variant="subtitle2">
                        Account active since:
                    </Typography>
                    <Typography fontSize={"14px"}>{moment(user.date_joined).format("Do MMM YYYY")}</Typography>
                </Box>
                {!canEnableMailBotPending && canEnableMailBot && (
                    <Box marginBottom={"16px"}>
                        <Button
                            onClick={() => {
                                handleActivateUser();
                            }}
                            variant="contained"
                        >
                            Activate MailBot
                        </Button>
                    </Box>
                )}
                <Divider
                    sx={{
                        background: "#D2D9E2",
                    }}
                />
                {!subscription ? (
                    <Box marginTop={"16px"}>
                        <Typography variant={md ? "h6" : "subtitle1"} gutterBottom fontWeight={600}>
                            Preferences
                        </Typography>
                        <BuySubscription
                            title={"You have not subscribed to any plan yet. Please subscribe to set preferences."}
                        />
                    </Box>
                ) : (
                    <Box marginTop={"16px"}>
                        <Typography variant={md ? "h6" : "subtitle1"} gutterBottom fontWeight={600}>
                            {mailBotActive ? "Preferences" : "Activate Emailzap to set preferences"}
                        </Typography>
                        {mailBotActive && <Preferences />}
                    </Box>
                )}
                <ConfirmDialog
                    open={showDeleteConfirmDialog}
                    onClose={() => setShowDeleteConfirmDialog(false)}
                    title="Are you sure you want to delete?"
                    content="Emailzap will stop categorising the emails that you receive. You will also lose all the trainings you provided to Emailzap."
                    secondaryActionText="No, cancel"
                    primaryActionText="Yes, confirm"
                    onPrimaryActionConfirm={handleDeleteUser}
                />
                <ConfirmDialog
                    open={showDeactivateConfirmDialog}
                    onClose={() => setShowDeactivateConfirmDialog(false)}
                    title="Do you want to delete or deactivate?"
                    content="Deactivating will pause EmailZap's categorisation and all the emails will start flowing back to your inbox. Deleting will also permanently delete all the trainings that you might have provided."
                    primaryActionText="Deactivate"
                    secondaryActionText="Delete"
                    onPrimaryActionConfirm={handleDeactivateUser}
                    onSecondaryActionConfirm={() => {
                        setShowDeleteConfirmDialog(true);
                    }}
                />
                <SnackBar
                    open={successMessage.length > 0}
                    handleClose={() => setSuccessMessage("")}
                    message={successMessage}
                    severity="success"
                />
                <SnackBar
                    open={errorMessage.length > 0}
                    handleClose={() => setErrorMessage("")}
                    message={errorMessage}
                    severity="error"
                />
            </Box>
        </Box>
    );
}
