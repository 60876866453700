import { Box, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export default function MobileTabs() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <Box
            sx={{
                background: "#F9FAFB",
                position: "sticky",
                top: 0,
                width: "100%",
                zIndex: (theme) => theme.zIndex.drawer - 2,
            }}
        >
            <Tabs
                variant="fullWidth"
                value={pathname}
                sx={{
                    "& .Mui-selected": {
                        fontWeight: 600,
                    },
                    borderBottom: 1,
                    borderColor: "divider",
                }}
            >
                <Tab
                    disableRipple
                    label="Auto cleaner"
                    value={"/mail-bot/auto-cleaner"}
                    onClick={() => navigate("/mail-bot/auto-cleaner")}
                />
                <Tab
                    disableRipple
                    label="Delete mails"
                    value={"/mail-bot/delete-emails"}
                    onClick={() => navigate("/mail-bot/delete-emails")}
                />
                <Tab
                    disableRipple
                    label="Unsubscribe"
                    value={"/mail-bot/unsubscribe"}
                    onClick={() => navigate("/mail-bot/unsubscribe")}
                />
            </Tabs>
        </Box>
    );
}
