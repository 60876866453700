import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function BuySubscription({
    title = "You have not subscribed to any plan yet. Please subscribe to continue using Emailzap.",
}) {
    const navigate = useNavigate();
    return (
        <Card>
            <CardContent>
                <Typography align="center" variant="subtitle2" color="error">
                    {title}
                </Typography>
            </CardContent>
            <CardActions
                sx={{
                    justifyContent: "center",
                }}
            >
                <Button
                    data-testid="subscribe-now"
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => {
                        navigate("/subscription");
                    }}
                >
                    Subscribe Now
                </Button>
            </CardActions>
        </Card>
    );
}
