import { Box, InputAdornment, TextField } from "@mui/material";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { Search } from "@mui/icons-material";

export function DataSearchBar({ searchText, setSearchText }) {
    const { md } = useCustomMediaQuery();
    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: md ? "436px" : "100%",
                height: "36px",
            }}
        >
            <TextField
                data-testid="data-search-bar"
                id="search"
                type="search"
                placeholder="Search"
                variant="outlined"
                fullWidth
                autoComplete="off"
                value={searchText}
                onChange={(e) => {
                    setSearchText(e.target.value);
                }}
                sx={{
                    background: "white",
                    outline: 0,
                    "#search:focus": {
                        borderColor: "transparent",
                        outline: "none",
                        boxShadow: "none",
                    },
                }}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        style: {
                            height: "36px",
                        },
                    },
                }}
            />
        </Box>
    );
}
