import { Box, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ExploreCard from "components/Home/ExploreCard";
import FeatureCard from "components/Home/FeatureCard";
import { maxContentWidthDesktop } from "defines";
import { useMailBotStatistics } from "hooks/api/mailbot/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { getDisplayNumber } from "utils/formatter";

function SummaryCard({ title, description }) {
    const { md } = useCustomMediaQuery();
    return (
        <Card
            variant="outlined"
            sx={{
                borderColor: "#CFD6EB",
                width: "100%",
                height: "100%",
                backgroundColor: "#EFF2F5",
            }}
        >
            <CardContent
                sx={{
                    width: "100%",
                    height: "100%",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Typography fontWeight={600} fontSize={"14px"} lineHeight={"20px"} gutterBottom>
                    {title}
                </Typography>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography fontWeight={600} lineHeight={"36px"} fontSize={!md ? "20px" : "24px"}>
                        {description}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

export default function Home() {
    const navigate = useNavigate();
    const { md } = useCustomMediaQuery();
    const { data: statistics, isPending: isStatisticsPending } = useMailBotStatistics();
    return (
        <Box marginTop={"10px"}>
            <Box p={1} margin={"auto"} maxWidth={maxContentWidthDesktop}>
                <Box marginBottom={"20px"}>
                    <Typography
                        gutterBottom
                        fontWeight={600}
                        variant={!md ? "subtitle2" : "h6"}
                        lineHeight={!md ? "16px" : "24.2px"}
                        color="#2B333B"
                    >
                        EmailZap’s auto-cleaner
                    </Typography>
                    <ExploreCard
                        dataTestId={"explore-auto-cleaner"}
                        title="Auto clean your mailbox"
                        description={
                            "This is EmailZap’s magic. Discover which senders have been blocked by EmailZap from sending emails to your inbox"
                        }
                        onClick={() => {
                            navigate("/mail-bot/auto-cleaner");
                        }}
                    >
                        <Box
                            sx={{ background: "white" }}
                            minWidth={!md ? "114px" : "160px"}
                            height={!md ? "100px" : "84px"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            display={"flex"}
                        >
                            <img src="/images/home/frame_1.png" alt="Explore Card" />
                        </Box>
                    </ExploreCard>
                </Box>
                <Box>
                    <Typography
                        gutterBottom
                        fontWeight={600}
                        variant={!md ? "subtitle2" : "h6"}
                        lineHeight={"24.2px"}
                        color="#2B333B"
                    >
                        More options if you want to go an extra mile to sort out your inbox
                    </Typography>
                    <Box marginBottom={"20px"}>
                        <ExploreCard
                            dataTestId={"explore-delete-emails"}
                            title={"Delete unwanted mails"}
                            description={
                                "Delete bulk emails that no longer serve a purpose, keeping your inbox clean and manageable."
                            }
                            onClick={() => {
                                navigate("/mail-bot/delete-emails");
                            }}
                        >
                            <Box
                                sx={{ background: "white" }}
                                minWidth={!md ? "114px" : "160px"}
                                height={!md ? "100px" : "84px"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                display={"flex"}
                            >
                                <img src="/images/home/frame_2.png" alt="Explore Card" />
                            </Box>
                        </ExploreCard>
                    </Box>
                    <ExploreCard
                        dataTestId={"explore-unsubscribe"}
                        title={"Unsubscribe from mails"}
                        description={
                            "Unsubscribe from senders that spam you. EmailZap will get you unsubscribed from all mailing lists of a sender in 1 click"
                        }
                        onClick={() => {
                            navigate("/mail-bot/unsubscribe");
                        }}
                    >
                        <Box
                            sx={{ background: "white" }}
                            minWidth={!md ? "114px" : "160px"}
                            height={!md ? "100px" : "84px"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            display={"flex"}
                        >
                            <img src="/images/home/frame_3.png" alt="Explore Card" />
                        </Box>
                    </ExploreCard>
                </Box>
            </Box>
            <Box
                sx={{
                    background: "#FFDC8D33",
                }}
            >
                <Grid
                    container
                    marginTop={"20px"}
                    display={"flex"}
                    p={1}
                    marginLeft={"auto"}
                    marginRight={"auto"}
                    maxWidth={maxContentWidthDesktop}
                >
                    <Grid size={!md ? 12 : 6}>
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={!md ? "center" : "flex-start"}
                            justifyContent={"center"}
                            height={"100%"}
                        >
                            <Box>
                                <Typography
                                    fontWeight={600}
                                    variant={!md ? "subtitle2" : "h6"}
                                    lineHeight={"24.2px"}
                                    align={!md ? "center" : "inherit"}
                                    color="#2B333B"
                                    gutterBottom
                                >
                                    EmailZap benefits
                                </Typography>
                                <Typography
                                    fontWeight={400}
                                    align={!md ? "center" : "inherit"}
                                    variant={!md ? "body2" : "body1"}
                                    lineHeight={"20px"}
                                    color="#1C1C1C"
                                    width={md ? "338px" : "300px"}
                                    gutterBottom
                                >
                                    EmailZap is your automated email assistant that keeps your mailbox clean through
                                    multiple features
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid size={!md ? 12 : 6}>
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"flex-end"}
                            gap={"14px"}
                            justifyContent={"center"}
                            height={"100%"}
                        >
                            <Box display={"flex"} flexDirection={!md ? "column" : "row"} gap={"14px"} width={"100%"}>
                                <FeatureCard
                                    title="Auto-Cleaner"
                                    description="Auto-cleaner will move unwanted emails automatically to “Zapped” label created by EmailZap. This ensures only important emails show up in your inbox"
                                />
                                <FeatureCard
                                    title="Important emails are always delivered to your inbox"
                                    description="Even if a sender is Zapped, important emails like OTPs, security alerts, or payment info will still reach your inbox, ensuring you don’t miss them."
                                />
                            </Box>
                            <Box display={"flex"} flexDirection={!md ? "column" : "row"} gap={"14px"} width={"100%"}>
                                <FeatureCard
                                    title="Auto-Delete future emails"
                                    description="The bulk-delete feature lets you auto-delete future emails from specific senders, ensuring they never appear in your inbox again."
                                />
                                <FeatureCard
                                    title="Most powerful unsubscriber"
                                    description="EmailZap not only unsubscribes you from all mailing lists of a sender but will also auto-delete future emails from them if they don’t honour your unsubscribe request"
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box p={2} marginTop={"20px"} maxWidth={maxContentWidthDesktop} marginLeft={"auto"} marginRight={"auto"}>
                <Typography
                    fontWeight={600}
                    lineHeight={"24.2px"}
                    color="#2B333B"
                    gutterBottom
                    variant={!md ? "subtitle2" : "h6"}
                >
                    EmailZap summary
                </Typography>
                <Typography
                    fontWeight={400}
                    variant={!md ? "body2" : "body1"}
                    lineHeight={"16.94px"}
                    color="#2B333B"
                    marginBottom={"20px"}
                >
                    Summary of everything that EmailZap has done so far
                </Typography>
                <Grid container spacing={3}>
                    <Grid size={!md ? 6 : 3}>
                        <SummaryCard
                            title={"Total email scanned"}
                            description={
                                isStatisticsPending ? "Loading..." : getDisplayNumber(statistics.messages_scanned)
                            }
                        />
                    </Grid>
                    <Grid size={!md ? 6 : 3}>
                        <SummaryCard
                            title={"Emails Zapped"}
                            description={
                                isStatisticsPending ? "Loading..." : getDisplayNumber(statistics.messages_zapped)
                            }
                        />
                    </Grid>
                    <Grid size={!md ? 6 : 3}>
                        <SummaryCard
                            title={"Emails deleted by you"}
                            description={
                                isStatisticsPending ? "Loading..." : getDisplayNumber(statistics.trashed_count)
                            }
                        />
                    </Grid>
                    <Grid size={!md ? 6 : 3}>
                        <SummaryCard
                            title={"Senders Unsubscribed"}
                            description={
                                isStatisticsPending ? "Loading..." : getDisplayNumber(statistics.senders_unsubscribed)
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
