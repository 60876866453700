import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import redditTrackingJs from "scripts/redditTracking";

export default function CheckoutCompletion() {
    const [redirectTimer, setRedirectTimer] = useState(5);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const timerInterval = setInterval(() => {
            const queryParams = new URLSearchParams(location.search);
            if (redirectTimer === 1) {
                clearInterval(timerInterval);
                navigate({ pathname: "/", search: queryParams.toString() });
            } else {
                setRedirectTimer(redirectTimer - 1);
            }
        }, 1000);

        return () => {
            clearInterval(timerInterval);
        };
    }, [redirectTimer]);

    return (
        <>
            <Helmet>
                <script type="text/javascript">{redditTrackingJs}</script>
            </Helmet>
            <div className="flex flex-col items-center justify-center h-screen">
                <div className="mb-4 text-2xl font-semibold text-green-500">Payment Successful</div>
                <svg
                    className="w-12 h-12 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <p className="mt-4">Please wait while we redirect you in {redirectTimer} seconds...</p>
            </div>
        </>
    );
}
