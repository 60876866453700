import { Backdrop, Box, Pagination, Typography } from "@mui/material";
import customLoaderAnimation from "components/Common/loader.json";
import useOnboardingLoader from "hooks/useOnboardingLoader";
import Lottie from "lottie-react";
import { useState } from "react";
import { useDebounce } from "react-use";
import { DataSearchBar } from "./DataSearchBar";

const getQueryParams = ({ searchQuery, ordering }) => {
    let q = {
        ordering: ordering,
    } as any;
    // length will be 0 when user clears the sorting, in which case default ordering should be applied
    if (searchQuery.length > 0) {
        // Only search by sender_email for now
        q = { ...q, sender_email: searchQuery };
    }
    return q;
};

export function CustomLoader({ open, percentage, children }) {
    return (
        <Box position="relative">
            <Backdrop
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer - 3,
                    position: "absolute",
                    backdropFilter: "blur(1px)",
                    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(208, 208, 208, 0.6) 95.25%)",
                }}
                open={open}
            >
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    top={"0"}
                    position={"absolute"}
                    width={"80%"}
                >
                    <Lottie
                        animationData={customLoaderAnimation}
                        loop={true}
                        style={{
                            height: "140px",
                            width: "140px",
                        }}
                    />
                    <Typography
                        variant="body1"
                        fontWeight={600}
                        align="center"
                        sx={{
                            textShadow: "-1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF",
                        }}
                    >
                        Analysed {percentage}% of your inbox.
                    </Typography>
                    <Typography
                        variant="body1"
                        fontWeight={500}
                        align="center"
                        sx={{
                            textShadow: "-1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF",
                        }}
                    >
                        Will take maximum of 10 minutes.
                    </Typography>
                </Box>
            </Backdrop>
            {children}
        </Box>
    );
}

export default function DataCard({ useData, pageSize, renderCard, ordering }) {
    const [searchText, setSearchText] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const { showOnboardingLoader, inboxScannedPercentage } = useOnboardingLoader();
    const q = getQueryParams({ searchQuery, ordering });
    const listData = useData({ page: page + 1, pageSize, queryParams: q });
    useDebounce(
        () => {
            setPage(0);
            setSearchQuery(searchText);
        },
        500,
        [searchText],
    );
    return (
        <Box
            sx={{
                marginTop: "10px",
            }}
        >
            <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"} marginBottom={"10px"}>
                <Box>
                    <Typography fontWeight={600} lineHeight={"24px"} gutterBottom variant={"subtitle2"}>
                        Emails received
                    </Typography>
                    <Typography fontWeight={400} variant={"subtitle2"} gutterBottom>
                        Listing down all the mails received in your mailbox
                    </Typography>
                </Box>
                <DataSearchBar searchText={searchText} setSearchText={setSearchText} />
            </Box>
            {/* if no sender profile yet created during onboarding, only show loader */}
            <CustomLoader
                open={!listData.isPending && listData.data.length === 0 && showOnboardingLoader}
                percentage={inboxScannedPercentage}
            >
                <></>
            </CustomLoader>
            {!listData.isPending && listData.data.length === 0 && !showOnboardingLoader && (
                <Box marginTop={"16px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Typography variant="subtitle2" color="textSecondary">
                        No data available
                    </Typography>
                </Box>
            )}
            {!listData.isPending && listData.data.length > 0 && (
                <Box>
                    <CustomLoader open={showOnboardingLoader} percentage={inboxScannedPercentage}>
                        {listData.data.map((profile) => (
                            <Box
                                marginBottom={"10px"}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                key={profile.id}
                            >
                                {renderCard({ profile })}
                            </Box>
                        ))}
                    </CustomLoader>
                    <Box display={"flex"} justifyContent={"center"}>
                        <Pagination
                            count={Math.ceil(listData.totalResults / pageSize)}
                            page={page + 1}
                            color="primary"
                            onChange={(_, newPage) => {
                                setPage(newPage - 1);
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}
