import { backendServerAxiosInstance } from "services";

export const updateMailBotPreferences = async (preferences: any) => {
    const response = await backendServerAxiosInstance.put("/mailbot/preferences/", { preferences: preferences });
    return response.data;
};

export const deleteUserMailBotProfile = async (id) => {
    const response = await backendServerAxiosInstance.delete(`/mailbot/mailbot-profile/${id}/`);
    return response.data;
};

export const updateUserTrainingStatus = async ({ profiles, trainingLabel }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/sender-profiles/update-training/`, {
        sender_profiles: profiles,
        user_training: trainingLabel,
    });
    return response.data;
};

export const deactivateMailBotProfile = async ({ preferences }) => {
    return updateMailBotPreferences({ ...preferences, mailbot_enabled: false });
};

export const activateMailBotProfile = async ({ preferences }) => {
    return updateMailBotPreferences({ ...preferences, mailbot_enabled: true });
};

export const switchMailProfile = async (user_mailbot_profile_id) => {
    const response = await backendServerAxiosInstance.post("/mailbot/secondary-profiles/switch/", {
        user_profile_to_switch_id: user_mailbot_profile_id,
    });
    return response.data;
};
export const createUserAction = async ({ senderProfileId, action, actionReason }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/sender-profiles/${senderProfileId}/user-action/`, {
        action: action,
        action_reason: actionReason,
    });
    return response.data;
};

export const deleteUserAction = async ({ senderProfileId }) => {
    const response = await backendServerAxiosInstance.delete(
        `/mailbot/sender-profiles/${senderProfileId}/user-action/`,
    );
    return response.data;
};

export const sendSupportEmail = async ({ text }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/support/`, { body: text });
    return response.data;
};

export const deleteHistoricalEmails = async ({ senderProfileId, action }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/sender-profiles/${senderProfileId}/bulk-delete/`, {
        action: action,
    });
    return response.data;
};

export const unsubscribeSender = async ({ senderProfileId }) => {
    const response = await backendServerAxiosInstance.post(
        `/mailbot/unsubscribe-details/${senderProfileId}/unsubscribe/`,
    );
    return response.data;
};

export const markNewUIViewed = async () => {
    const response = await backendServerAxiosInstance.post(`/mailbot/mailbot-profile/new-ui-viewed/`);
    return response.data;
};

export const mailOperations = async ({ params }) => {
    const response = await backendServerAxiosInstance.post(`/mailbot/mail-operations/`, params);
    return response.data;
};
