import Loader from "components/Common/Loader";
import { useCreateCheckoutSession } from "hooks/api/applications";
import { useMailBotMetadata } from "hooks/api/mailbot/queries";
import { useLatestSubscription, usePricings } from "hooks/api/payments/queries";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const StripeCheckout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const createCheckoutSessionMutation = useCreateCheckoutSession();
    const { data: pricings, isPending: pricesPending } = usePricings();
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const { data: mailbotMetadata, isPending: mailbotMetadataPending } = useMailBotMetadata();
    const queryParams = new URLSearchParams(location.search);
    const pricingNickname = queryParams.get("stripe_payment_plan") || "";
    const mailbotProfileCreated = queryParams.get("mailbot_profile_created") === "True";
    const useCoupon = queryParams.get("use_coupon") === "true";
    const redirectToStripeCheckout = (priceId: string, useCoupon: boolean) => {
        createCheckoutSessionMutation.mutate(
            { data: { price: priceId, use_coupon: useCoupon }, queryParams: queryParams },
            {
                onSuccess: (checkoutSession) => {
                    window.location = checkoutSession.redirect_url;
                },
            },
        );
    };
    useEffect(() => {
        if (!pricesPending && !subscriptionPending && !mailbotMetadataPending) {
            let areAllScopesGranted = true;
            if (mailbotMetadata.all_scopes_granted === false) {
                areAllScopesGranted = false;
            }
            const price = pricings.find((pricing) => pricing.nickname === pricingNickname);
            if (mailbotProfileCreated && areAllScopesGranted && !subscription && price) {
                redirectToStripeCheckout(price.id, useCoupon);
            } else {
                navigate("/");
            }
        }
    }, [pricesPending, subscriptionPending, mailbotMetadataPending]);
    return <Loader />;
};

export default StripeCheckout;
