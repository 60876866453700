import { Button, Typography } from "@mui/material";
import { useDeleteConfiguredSenderWorkflows, useUpdateUserTrainingStatus } from "hooks/api/mailbot/mutations";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { SnackbarMessageContext } from "layouts/AppLayout";
import { useContext } from "react";

export default function UndoButton({ profile, trainingLabel = "", undoTraining = false, undoCustomRule = false }) {
    const deleteConfiguredSenderWorkflowsMutation = useDeleteConfiguredSenderWorkflows();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const updateUserTrainingStatusMutation = useUpdateUserTrainingStatus();
    const { setSuccessMessage, setSuccessMessageTitle } = useContext(SnackbarMessageContext);
    const { md } = useCustomMediaQuery();
    if (mailBotEnabledPending) {
        return null;
    }
    const disabled = !mailBotEnabled;
    return (
        <Button
            disabled={disabled}
            disableElevation
            data-testid={`undo-${profile.sender_email}`}
            onClick={() => {
                if (undoTraining) {
                    updateUserTrainingStatusMutation.mutate(
                        { profiles: [profile], trainingLabel: trainingLabel },
                        {
                            onSuccess: () => {
                                setSuccessMessageTitle("");
                                setSuccessMessage("Action undone successfully");
                            },
                        },
                    );
                } else if (undoCustomRule) {
                    deleteConfiguredSenderWorkflowsMutation.mutate(
                        { senderProfileId: profile.id },
                        {
                            onSuccess: () => {
                                setSuccessMessageTitle("");
                                setSuccessMessage("Action undone successfully");
                            },
                        },
                    );
                }
            }}
            sx={{
                background: "#EFBA421A",
                borderRadius: "4px",
                width: md ? "100px" : "80px",
                height: md ? "40px" : "30px",
                "&:hover": {
                    background: "#FFAE02",
                },
                border: !disabled ? "1px solid #EFBA42" : "",
            }}
            variant="contained"
        >
            <Typography fontWeight={500} color="#1B1301" variant={md ? "body2" : "caption"}>
                Undo
            </Typography>
        </Button>
    );
}
