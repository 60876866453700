import {
    Badge,
    Box,
    Chip,
    Pagination,
    Stack,
    styled,
    Tab,
    TablePaginationProps,
    Tabs,
    Typography,
} from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridOverlay,
    gridPageCountSelector,
    GridPagination,
    GridSortModel,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid";
import Loader from "components/Common/Loader";
import customLoaderAnimation from "components/Common/loader.json";
import useOnboardingLoader from "hooks/useOnboardingLoader";
import Lottie from "lottie-react";
import { useState } from "react";
import { useDebounce } from "react-use";
import { DataSearchBar } from "./DataSearchBar";

const getQueryParams = ({ ordering, searchQuery, filter, tabFilter }) => {
    let q = {} as any;
    // length will be 0 when user clears the sorting, in which case default ordering should be applied
    if (ordering.length > 0) {
        if (ordering[0].sort === "asc") {
            q = { ordering: ordering[0].field };
        } else {
            q = { ordering: `-${ordering[0].field}` };
        }
        // If field is not sender_email then always include sender_email as a secondary ordering field
        if (ordering[0].field !== "sender_email") {
            q = { ordering: `${q.ordering},sender_email` };
        }
    }
    if (searchQuery.length > 0) {
        // Only search by sender_email for now
        q = { ...q, sender_email: searchQuery };
    }
    if (!!tabFilter) {
        q = { ...q, ...tabFilter.reduce((acc, f) => ({ ...acc, ...f }), {}) };
    }
    if (!!filter) {
        // filter = { label: "Unsubscribed", condition: [{unsubscribe: true, deleted: false}] }
        // q = { ...q, unsubscribe: true, deleted: false }
        q = { ...q, ...filter.condition.reduce((acc, f) => ({ ...acc, ...f }), {}) };
    }
    return q;
};

function CustomPagination({
    page,
    onPageChange,
    className,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    // page is 0 indexed but Pagination component is 1 indexed
    return (
        <Pagination
            color="primary"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event as any, newPage - 1);
            }}
        />
    );
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .no-results-primary": {
        fill: "#3D4751",
        ...theme.applyStyles("light", {
            fill: "#AEB8C2",
        }),
    },
    "& .no-results-secondary": {
        fill: "#1D2126",
        ...theme.applyStyles("light", {
            fill: "#E8EAED",
        }),
    },
}));

function CustomNoResultsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                width={96}
                viewBox="0 0 523 299"
                aria-hidden
                focusable="false"
            >
                <path
                    className="no-results-primary"
                    d="M262 20c-63.513 0-115 51.487-115 115s51.487 115 115 115 115-51.487 115-115S325.513 20 262 20ZM127 135C127 60.442 187.442 0 262 0c74.558 0 135 60.442 135 135 0 74.558-60.442 135-135 135-74.558 0-135-60.442-135-135Z"
                />
                <path
                    className="no-results-primary"
                    d="M348.929 224.929c3.905-3.905 10.237-3.905 14.142 0l56.569 56.568c3.905 3.906 3.905 10.237 0 14.143-3.906 3.905-10.237 3.905-14.143 0l-56.568-56.569c-3.905-3.905-3.905-10.237 0-14.142ZM212.929 85.929c3.905-3.905 10.237-3.905 14.142 0l84.853 84.853c3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0l-84.853-84.853c-3.905-3.905-3.905-10.237 0-14.142Z"
                />
                <path
                    className="no-results-primary"
                    d="M212.929 185.071c-3.905-3.905-3.905-10.237 0-14.142l84.853-84.853c3.905-3.905 10.237-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-84.853 84.853c-3.905 3.905-10.237 3.905-14.142 0Z"
                />
                <path
                    className="no-results-secondary"
                    d="M0 43c0-5.523 4.477-10 10-10h100c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 53 0 48.523 0 43ZM0 89c0-5.523 4.477-10 10-10h80c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 99 0 94.523 0 89ZM0 135c0-5.523 4.477-10 10-10h74c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 181c0-5.523 4.477-10 10-10h80c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 227c0-5.523 4.477-10 10-10h100c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM523 227c0 5.523-4.477 10-10 10H413c-5.523 0-10-4.477-10-10s4.477-10 10-10h100c5.523 0 10 4.477 10 10ZM523 181c0 5.523-4.477 10-10 10h-80c-5.523 0-10-4.477-10-10s4.477-10 10-10h80c5.523 0 10 4.477 10 10ZM523 135c0 5.523-4.477 10-10 10h-74c-5.523 0-10-4.477-10-10s4.477-10 10-10h74c5.523 0 10 4.477 10 10ZM523 89c0 5.523-4.477 10-10 10h-80c-5.523 0-10-4.477-10-10s4.477-10 10-10h80c5.523 0 10 4.477 10 10ZM523 43c0 5.523-4.477 10-10 10H413c-5.523 0-10-4.477-10-10s4.477-10 10-10h100c5.523 0 10 4.477 10 10Z"
                />
            </svg>
            <Box sx={{ mt: 2 }}>No results found.</Box>
        </StyledGridOverlay>
    );
}

type TabsType = {
    label: string;
    condition: any[];
    badgeContent?: (data: any) => number;
    filters: { label: string; condition: any[] }[];
    columnsVisibilityModel?: any;
};

function CustomLoader({ percentage }) {
    return (
        <GridOverlay
            sx={{
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(208, 208, 208, 0.6) 95.25%)",
                backdropFilter: "blur(2px)",
                alignItems: "flex-start",
            }}
        >
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Lottie
                    animationData={customLoaderAnimation}
                    loop={true}
                    style={{
                        height: "100px",
                        width: "100px",
                    }}
                />
                <Box>
                    <Typography
                        fontWeight={600}
                        sx={{
                            textShadow: "-1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF",
                        }}
                    >
                        Analysed {percentage}% of your inbox.
                    </Typography>
                    <Typography
                        fontWeight={500}
                        sx={{
                            textShadow: "-1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF",
                        }}
                    >
                        Will take maximum of 10 minutes.
                    </Typography>
                </Box>
            </Box>
        </GridOverlay>
    );
}
export default function DataTable({
    tabs,
    pageSize,
    columns,
    useData,
    defaultOrdering,
}: {
    tabs: TabsType[];
    pageSize: number;
    columns: GridColDef[];
    useData: any;
    defaultOrdering: GridSortModel;
}) {
    const [ordering, setOrdering] = useState<any>(defaultOrdering);
    const [searchText, setSearchText] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState<any>();
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [tabFilter, setTabFilter] = useState(tabs[0].condition);
    const q = getQueryParams({ ordering, searchQuery, filter, tabFilter });
    const { showOnboardingLoader, inboxScannedPercentage } = useOnboardingLoader();
    const listData = useData({ page: page + 1, pageSize, queryParams: q });

    useDebounce(
        () => {
            setPage(0);
            setSearchQuery(searchText);
        },
        500,
        [searchText],
    );
    const paginationModel = { page: page, pageSize: pageSize };
    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"}>
                <Box>
                    <Typography fontWeight={600} lineHeight={"24px"} gutterBottom variant={"subtitle1"}>
                        Emails received
                    </Typography>
                    <Typography fontWeight={400} variant={"subtitle1"} gutterBottom>
                        Listing down all the mails received in your mailbox
                    </Typography>
                </Box>
                <DataSearchBar searchText={searchText} setSearchText={setSearchText} />
            </Box>
            <Box marginBottom={"10px"} borderBottom={"1px solid #D2D9E2"}>
                <Tabs
                    value={selectedTab.label}
                    sx={{
                        "& .Mui-selected": {
                            fontWeight: 600,
                        },
                    }}
                    textColor="inherit"
                    TabIndicatorProps={{
                        sx: {
                            borderTopLeftRadius: "4px",
                            borderTopRightRadius: "4px",
                        },
                    }}
                >
                    {tabs.map((tab) => (
                        <Tab
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            data-testid={`tab-${tab.label}`}
                            onClick={() => {
                                setTabFilter(tab.condition);
                                setSelectedTab(tab);
                                // On tab change most of the states will have to be reset
                                setFilter(undefined);
                                setSearchText("");
                                setSearchQuery("");
                                setPage(0);
                                setOrdering(defaultOrdering);
                            }}
                            key={tab.label}
                            label={
                                tab?.badgeContent && !listData.isPending ? (
                                    <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                                        {tab.label}
                                        <Badge
                                            color="primary"
                                            badgeContent={tab.badgeContent(listData)}
                                            max={100}
                                            showZero
                                        />
                                    </Box>
                                ) : (
                                    tab.label
                                )
                            }
                            value={tab.label}
                        />
                    ))}
                </Tabs>
            </Box>
            {/* First two filters and rest in dropdown menu */}
            {selectedTab.filters.length > 0 && (
                <Stack direction={"row"} spacing={1} marginBottom={"10px"}>
                    {selectedTab.filters.map((f) => (
                        <Chip
                            key={f.label}
                            label={f.label}
                            size="medium"
                            color="default"
                            data-testid={`filter-${f.label}`}
                            variant={f.label === filter?.label ? "filled" : "outlined"}
                            onClick={() => {
                                if (f.label === filter?.label) {
                                    setFilter(undefined);
                                    setPage(0);
                                    return;
                                }
                                setFilter(f);
                                setPage(0);
                            }}
                        />
                    ))}
                </Stack>
            )}
            {listData.isPending && (
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
                    <Loader fullScreen={false} />
                </Box>
            )}
            {!listData.isPending && (
                <DataGrid
                    sx={{
                        background: "#F9FAFB",
                        "& .MuiDataGrid-cell:hover": {
                            color: "primary.dark",
                        },
                        "& .MuiDataGrid-cell:focus": {
                            outline: " none",
                        },
                    }}
                    rowHeight={50}
                    rows={listData.data}
                    columnHeaderHeight={40}
                    columns={columns}
                    rowCount={listData.totalResults}
                    sortingMode="server"
                    filterMode="server"
                    paginationMode="server"
                    disableColumnFilter
                    disableDensitySelector
                    disableColumnSelector
                    disableColumnResize
                    autoHeight={true}
                    paginationModel={paginationModel}
                    sortModel={ordering}
                    loading={showOnboardingLoader}
                    pageSizeOptions={[pageSize]}
                    slots={{
                        pagination: (props) => <GridPagination ActionsComponent={CustomPagination} {...props} />,
                        loadingOverlay: () => <CustomLoader percentage={inboxScannedPercentage} />,
                        noRowsOverlay: () => <CustomNoResultsOverlay />,
                    }}
                    initialState={{
                        pagination: { paginationModel },
                        sorting: { sortModel: ordering },
                        density: "comfortable",
                    }}
                    columnVisibilityModel={selectedTab.columnsVisibilityModel || {}}
                    onPaginationModelChange={(newModel) => {
                        setPage(newModel.page);
                    }}
                    onSortModelChange={(sortModel) => {
                        // Reset page to 0 when sorting is changed
                        setPage(0);
                        // Reset search query when sorting is changed
                        setOrdering(sortModel);
                    }}
                />
            )}
        </Box>
    );
}
