import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useOnlineStatus } from "hooks/useOnlineStatus";

/**
 * Currently made this component uncontrolled, as the dialog is only shown when the user is offline.
 * We can make it controlled and reduce the complexity of the component by passing the open state, onClose handler, etc.
 */
export default function OfflineStatusDialog() {
    const isOnline = useOnlineStatus();
    return (
        <Dialog open={!isOnline}>
            <DialogTitle>Offline</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You cannot use EmailZap while offline. Please check your internet connection and try again.
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
