import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

export default function ExploreCard({ title, description, onClick, children, dataTestId }) {
    const { md } = useCustomMediaQuery();
    return (
        <Card
            variant="outlined"
            sx={{
                backgroundColor: "#EFF2F5",
                width: "100%",
                padding: "10px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: !md ? "column" : "row",
                    gap: "16px",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>
                    {children}
                    <Box marginLeft={!md ? "10px" : "20px"} width={md ? "530px" : "100%"}>
                        <Typography
                            gutterBottom
                            fontWeight={600}
                            lineHeight={!md ? "16.94px" : "20px"}
                            color={"#1C1C1C"}
                            variant={!md ? "subtitle2" : "subtitle1"}
                        >
                            {title}
                        </Typography>
                        <Typography lineHeight={!md ? "16.94px" : "20px"} color={"#1C1C1C"} variant={"body2"}>
                            {description}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: md ? "175px" : "100%",
                    }}
                >
                    <Button
                        data-testid={dataTestId}
                        variant="contained"
                        disableElevation
                        fullWidth
                        onClick={onClick}
                        sx={{
                            height: "42px",
                            fontWeight: 600,
                        }}
                    >
                        Explore
                    </Button>
                </Box>
            </Box>
        </Card>
    );
}
