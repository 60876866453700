/**
 * Add commas to the count
 * Example: 1000000 -> 1,000,000
 * @param count
 */
export const getDisplayNumber = (count) => {
    if (count === undefined || count === null) {
        return "0";
    }
    return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Calculate initials of the name with maximum length of `maxLength`
 * Example: John Doe -> JD
 * @param name Sender name
 * @param maxLength Maximum length of the initials
 * @returns
 */
export const nameInitials = (name, maxLength = 1) => {
    return name
        .split(" ")
        .map((part) => part[0])
        .slice(0, maxLength)
        .join("")
        .toUpperCase();
};
