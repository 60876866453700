import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mailbotKeys } from "queryKeyFactory";
import {
    activateMailBotProfile,
    updateMailBotPreferences,
    deactivateMailBotProfile,
    deleteUserMailBotProfile,
    sendSupportEmail,
    deleteHistoricalEmails,
    unsubscribeSender,
    updateUserTrainingStatus,
    deleteUserAction,
    createUserAction,
    markNewUIViewed,
    mailOperations,
} from "services/mailbot/mutations";

export function useUpdateMailBotPreferences() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateMailBotPreferences,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.preferences() });
        },
    });
}

export function useActivateMailBotProfile() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: activateMailBotProfile,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.preferences() });
        },
    });
}

export function useDeactivateMailBotProfile() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deactivateMailBotProfile,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.preferences() });
        },
    });
}

export function useDeleteUserMailBotProfile() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteUserMailBotProfile,
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
}

export function useSendSupportEmail() {
    return useMutation({
        mutationFn: sendSupportEmail,
    });
}

export function useDeleteHistoricalEmails() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: mailbotKeys.deleteHistoricalEmails(),
        mutationFn: deleteHistoricalEmails,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfilesAllPages() });
        },
    });
}

export function useUnsubscribeSender() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: unsubscribeSender,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["mailbot", "sender-unsubscribe-details"] });
        },
    });
}

export function useUpdateUserTrainingStatus() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: updateUserTrainingStatus,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfilesAllPages() });
        },
    });
}

export function useDeleteConfiguredSenderWorkflows() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteUserAction,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfilesAllPages() });
        },
    });
}

export function useConfigureSenderWorkflows() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: createUserAction,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.senderProfilesAllPages() });
        },
    });
}

export function useMarkNewUIViewed() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: markNewUIViewed,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: mailbotKeys.preferences() });
        },
    });
}

export function useMailBotOperations() {
    return useMutation({
        mutationFn: mailOperations,
    });
}
