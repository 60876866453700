import { JsonSchema } from "@jsonforms/core";
import { IJsonWorkflow } from "types/maintypes";
import { isSubset } from "utils";

export const convertDatetimeStrings = (jsonObj) => {
    for (const key in jsonObj) {
        if (Object.prototype.hasOwnProperty.call(jsonObj, key)) {
            if (key.endsWith("_date_time")) {
                try {
                    // Assuming your date format is known, replace 'YYYY-MM-DD HH:mm:ss' with your actual format
                    const formattedDatetime = new Date(jsonObj[key]).toISOString();
                    jsonObj[key] = formattedDatetime;
                } catch (error) {
                    // Handle the case where the date string is not in the expected format
                    console.error(`Error converting datetime for key: ${key}`);
                }
            }
        }
    }

    return jsonObj;
};

export const validateWorkflow = (workflow: IJsonWorkflow, componentDefinitions: object[]) => {
    // Compare tags used and tags allowed to validate workflow
    // TODO: Add other rules for validation

    const triggerTag = workflow["trigger"].tag;
    const constraintTags: string[] = [];
    workflow["constraints"].forEach((constraintGroup) => {
        constraintGroup.forEach((constraint) => constraintTags.push(constraint.tag));
    });
    const actionTags = workflow["actions"].map((action) => action.tag);

    const tagsUsed: string[] = [triggerTag, ...constraintTags, ...actionTags];
    const tagsAllowed: string[] = componentDefinitions.map((componentDef: any) => componentDef.tag);

    // There is should atleast one tag used in every section
    const condition1 = !!triggerTag && !!constraintTags.length && !!actionTags.length;
    // Used tags should be allowed
    const condition2 = isSubset(tagsUsed, tagsAllowed);

    return condition1 && condition2;
};

export const getDefaultParams = (componentDefinition: JsonSchema) => {
    const defaultParams = {};
    const paramTags = Object.keys(componentDefinition.properties || {});

    paramTags.forEach((tag) => {
        // For arrays, we want first element to be shown by default.
        if (componentDefinition.properties?.[tag].type === "array") {
            defaultParams[tag] = [""];
        }
    });
    return defaultParams;
};
