import React from "react";
import { IJsonWorkflowAction, IJsonWorkflowConstraint, IJsonWorkflowTrigger, IOption } from "types/maintypes";
import { deepCopy } from "utils";
import * as _ from "underscore";
import { materialRenderers, materialCells } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { convertDatetimeStrings, getDefaultParams } from "utils/workflow";
import { JsonSchema, Generate, createAjv } from "@jsonforms/core";
import { Button, MenuItem, Select } from "@mui/material";
import { GridDeleteIcon } from "@mui/x-data-grid";

const SECTION_TO_TYPE = {
    trigger: "trigger",
    actions: "action",
    constraints: "constraint",
};

interface DynamicFormProps {
    sectionKey: "trigger" | "actions" | "constraints";
    jsonObject: IJsonWorkflowAction[] | IJsonWorkflowTrigger[] | IJsonWorkflowConstraint[][];
    setJsonObject: any;
    componentDefinitions: object[];
}

const WorkflowSection: React.FC<DynamicFormProps> = ({
    sectionKey,
    jsonObject,
    setJsonObject,
    componentDefinitions,
}) => {
    /**
     * Note: For constraints, we have a format like: (1) or (2 and 3) or (4 and 5 and 6)
     * @param newOptionValue - New option selected in the dropdown
     * @param groupIndex - This parameter is to identify the group ie (1), (2 and 3), (4 and 5 and 6)
     * @param fnIndex - This parameter is to identify the function within the group ie 4, 5, 6
     * @param newSchema - Schema of the parameter that is being selected
     */
    const onDropdownOptionChange = (
        newOptionValue: any,
        groupIndex: number,
        fnIndex: number,
        newSchema: JsonSchema,
    ) => {
        let updatedJsonObject: any = deepCopy(jsonObject);
        const defaultParams = getDefaultParams(newSchema);
        // Constraints
        if (sectionKey === "constraints") {
            if (updatedJsonObject[groupIndex] === undefined) {
                updatedJsonObject = [
                    [
                        {
                            tag: newOptionValue,
                            params: defaultParams,
                        },
                    ],
                ];
                setJsonObject(sectionKey, updatedJsonObject);
            } else {
                const oldOptionValue = updatedJsonObject[groupIndex][fnIndex]["tag"];
                if (oldOptionValue !== newOptionValue) {
                    updatedJsonObject[groupIndex][fnIndex] = {
                        tag: newOptionValue,
                        params: defaultParams,
                    };
                    setJsonObject(sectionKey, updatedJsonObject);
                }
            }
        }
        // Actions/Trigger
        else {
            if (updatedJsonObject[fnIndex] === undefined) {
                updatedJsonObject = [
                    {
                        tag: newOptionValue,
                        params: defaultParams,
                    },
                ];
                setJsonObject(sectionKey, updatedJsonObject);
            } else {
                const oldOptionValue = updatedJsonObject[fnIndex]["tag"];
                if (oldOptionValue !== newOptionValue) {
                    updatedJsonObject[fnIndex] = {
                        tag: newOptionValue,
                        params: defaultParams,
                    };
                    setJsonObject(sectionKey, updatedJsonObject);
                }
            }
        }
    };

    /**
     * Handles events when form data changes
     * @param newData - Updated form data
     * @param groupIndex - Index of the group that changed
     * @param fnIndex - Index of the function that changed
     */
    const onFormChange = (newData: any, groupIndex: number, fnIndex: number) => {
        const updatedJsonObject = deepCopy(jsonObject);

        // Data return by form contains timezone aware datetime strings, convert them to ISO format
        newData = convertDatetimeStrings(newData);

        // Constraints
        if (sectionKey === "constraints") {
            const oldData = updatedJsonObject[groupIndex][fnIndex]["params"];
            if (!_.isEqual(oldData, newData)) {
                updatedJsonObject[groupIndex][fnIndex]["params"] = newData;
                setJsonObject(sectionKey, updatedJsonObject);
            }
        }
        // Actions/Events
        else {
            const oldData = updatedJsonObject[fnIndex]["params"];
            if (!_.isEqual(oldData, newData)) {
                updatedJsonObject[fnIndex]["params"] = newData;
                setJsonObject(sectionKey, updatedJsonObject);
            }
        }
    };

    /**
     * Adds constraints to a group
     * @param groupIndex - New constraint will be added to this group
     * @param defaultOption - Parameter that will be selected by default
     * @param newSchema - Schema of the parameter that is being added
     */
    const addConstraintsToGroup = (groupIndex: number, defaultOption: IOption, newSchema: JsonSchema) => {
        const updatedJsonObject: any = deepCopy(jsonObject);
        const defaultParams = getDefaultParams(newSchema);
        updatedJsonObject[groupIndex].push({
            tag: defaultOption.value,
            params: defaultParams,
        });

        setJsonObject(sectionKey, updatedJsonObject);
    };

    /**
     * Adds new group of constraints
     */
    const addGroupOfConstraints = () => {
        const updatedJsonObject: any = deepCopy(jsonObject);
        const newDefinition = componentDefinitions.find((x) => x["tag"] === allOptions[0].value);
        if (!newDefinition?.["input"]) return;

        const defaultParams = getDefaultParams(newDefinition["input"]);
        updatedJsonObject.push([
            {
                tag: allOptions[0].value,
                params: defaultParams,
            },
        ]);
        setJsonObject(sectionKey, updatedJsonObject);
    };

    /**
     * Deletes a constraint from a constraint group
     * @param groupIndex - Index of the group that changed
     * @param fnIndex - Index of the function that changed
     */
    const deleteConstraint = (groupIndex: number, fnIndex: number) => {
        const updatedJsonObject: IJsonWorkflowConstraint[][] = deepCopy(jsonObject) as any;
        updatedJsonObject[groupIndex].splice(fnIndex, 1);
        // If the group is now empty, delete the entire group
        if (!updatedJsonObject[groupIndex].length) {
            updatedJsonObject.splice(groupIndex, 1);
        }
        setJsonObject(sectionKey, updatedJsonObject);
    };

    /**
     * Deletes a group of constraints
     * @param groupIndex - Index of the group that changed
     */
    const deleteConstraintGroup = (groupIndex: number) => {
        const updatedJsonObject: IJsonWorkflowConstraint[][] = deepCopy(jsonObject) as any;

        updatedJsonObject.splice(groupIndex, 1);
        // updatedJsonObject[groupIndex].splice(fnIndex, 1);
        setJsonObject(sectionKey, updatedJsonObject);
    };

    const filteredComponentDefinitions = componentDefinitions.filter((x) => x["type"] === SECTION_TO_TYPE[sectionKey]);

    // Dropdown options
    const allOptions: IOption[] = filteredComponentDefinitions.map((x) => ({
        label: x["name"],
        value: x["tag"],
    }));

    const emptyOption: IOption = {
        label: "",
        value: "",
    };

    return (
        <>
            {jsonObject.map(
                (group: IJsonWorkflowAction | IJsonWorkflowTrigger | IJsonWorkflowConstraint[], groupIndex: number) => {
                    const isArray = Array.isArray(group);
                    const functions = isArray ? group : [group];
                    const fnTags = functions.map((x) => x["tag"]);
                    const islastGroup = jsonObject.length - 1 === groupIndex;

                    // Filter out options already used in the current group
                    const allowedOptions = allOptions.filter((x) => !fnTags.includes(x.value));

                    return (
                        <div>
                            <div className="mb-5">
                                {sectionKey === "constraints" && (
                                    <div className="flex mb-2">
                                        <div>Constraint group {groupIndex + 1}</div>{" "}
                                        <Button
                                            disableElevation
                                            disableRipple
                                            disableFocusRipple
                                            disableTouchRipple
                                            onClick={() => {
                                                deleteConstraintGroup(groupIndex);
                                            }}
                                            startIcon={
                                                <GridDeleteIcon
                                                    sx={{
                                                        color: "black",
                                                    }}
                                                />
                                            }
                                        />
                                    </div>
                                )}
                                <div className="bg-white border border-black rounded-md p-3">
                                    <div key={groupIndex}>
                                        {functions.map((fn, fnIdx) => {
                                            const islastFunction = functions.length - 1 === fnIdx;
                                            const fnTag = fn["tag"];
                                            const fnParams = fn["params"] || {};

                                            const definition = componentDefinitions.find((x) => x["tag"] === fnTag);
                                            const schema = definition?.["input"];
                                            const uiSchema = definition?.["ui_schema"] ?? Generate.uiSchema(schema);
                                            const selectedOption =
                                                allOptions.find((x) => x.value === fnTag) ?? emptyOption;
                                            return (
                                                <div>
                                                    <div key={fnTag} className="mb-5">
                                                        <div className="flex gap-3 md:gap-5">
                                                            <div className="grow">
                                                                <Select
                                                                    value={selectedOption.value}
                                                                    onChange={(e) => {
                                                                        const newDefinition = componentDefinitions.find(
                                                                            (x) => x["tag"] === e.target.value,
                                                                        );
                                                                        newDefinition?.["input"] &&
                                                                            onDropdownOptionChange(
                                                                                e.target.value,
                                                                                groupIndex,
                                                                                fnIdx,
                                                                                newDefinition["input"],
                                                                            );
                                                                    }}
                                                                >
                                                                    {[selectedOption, ...allowedOptions].map(
                                                                        (option) => (
                                                                            <MenuItem
                                                                                key={option.value}
                                                                                value={option.value}
                                                                            >
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ),
                                                                    )}
                                                                </Select>
                                                                {schema && (
                                                                    <div className="ml-3 mt-2 json-schema-form">
                                                                        <JsonForms
                                                                            schema={schema}
                                                                            uischema={uiSchema}
                                                                            data={fnParams}
                                                                            renderers={materialRenderers}
                                                                            cells={materialCells}
                                                                            onChange={({ data, errors }) => {
                                                                                onFormChange(data, groupIndex, fnIdx);
                                                                            }}
                                                                            ajv={createAjv({ useDefaults: true })}
                                                                            validationMode="ValidateAndHide"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {sectionKey === "constraints" && (
                                                                <div className="flex grow-0 mt-2">
                                                                    <Button
                                                                        disableElevation
                                                                        disableRipple
                                                                        disableFocusRipple
                                                                        disableTouchRipple
                                                                        onClick={() => {
                                                                            deleteConstraint(groupIndex, fnIdx);
                                                                        }}
                                                                        startIcon={
                                                                            <GridDeleteIcon
                                                                                sx={{
                                                                                    color: "black",
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {!islastFunction && sectionKey === "constraints" && (
                                                        <div className="mb-5 font-semibold text-center">AND</div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                        {allowedOptions.length > 0 && sectionKey === "constraints" && (
                                            <div
                                                className="text-indigo-500 text-center cursor-pointer"
                                                onClick={() => {
                                                    const newDefinition = componentDefinitions.find(
                                                        (x) => x["tag"] === allowedOptions[0].value,
                                                    );
                                                    newDefinition?.["input"] &&
                                                        addConstraintsToGroup(
                                                            groupIndex,
                                                            allowedOptions[0],
                                                            newDefinition["input"],
                                                        );
                                                }}
                                            >
                                                Add more constraints to this group
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {!islastGroup && sectionKey === "constraints" && (
                                <div className="mb-5 font-semibold text-center">OR</div>
                            )}
                            {!islastGroup && sectionKey === "actions" && (
                                <div className="mb-5 font-semibold text-center">AND</div>
                            )}
                        </div>
                    );
                },
            )}
            {jsonObject.length === 0 && (
                <div className="bg-white border border-black rounded-md p-3">
                    <Select
                        value={emptyOption.value}
                        onChange={(e) => {
                            const newDefinition = componentDefinitions.find((x) => x["tag"] === e.target.value);
                            newDefinition?.["input"] &&
                                onDropdownOptionChange(e.target.value, 0, 0, newDefinition["input"]);
                        }}
                    >
                        {[emptyOption, ...allOptions].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            )}
            {allOptions.length > 0 && sectionKey === "constraints" && (
                <div className="text-indigo-500 cursor-pointer" onClick={addGroupOfConstraints}>
                    Add new set of constraints
                </div>
            )}
        </>
    );
};

export default WorkflowSection;
