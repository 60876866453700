import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

/**
 * TODO: Make all dialogs common and controlled
 */
export default function ConfirmDialog({
    open,
    onClose,
    title,
    content,
    secondaryActionText,
    primaryActionText,
    onPrimaryActionConfirm = () => {},
    onSecondaryActionConfirm = () => {},
}) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose();
                        onSecondaryActionConfirm();
                    }}
                    variant="contained"
                    sx={{
                        background: "white",
                    }}
                >
                    {secondaryActionText}
                </Button>
                <Button
                    onClick={() => {
                        onClose();
                        onPrimaryActionConfirm();
                    }}
                    variant="contained"
                >
                    {primaryActionText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
