import { Box, Button, Typography } from "@mui/material";
import ZappedText from "components/AutoCleaner/Zapped";
import DeleteIcon from "components/Common/Icons/DeleteIcon";
import ModifyIcon from "components/Common/Icons/ModifyIcon";
import FutureDeletePreference from "components/DeleteEmails/FutureDeletePreference";
import { UserAction, UserActionReason } from "defines";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { useState } from "react";

function EditDeletedSendersPreferenceButton({ senderEmail, setFutureDeletePreferenceOpen, option, setValue }) {
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const { md } = useCustomMediaQuery();
    if (mailBotEnabledPending) {
        return null;
    }
    const disabled = !mailBotEnabled;
    return (
        <Button
            data-testid={`modify-future-preference-${senderEmail}`}
            disabled={disabled}
            disableElevation
            disableRipple
            disableTouchRipple
            disableFocusRipple
            onClick={() => {
                setFutureDeletePreferenceOpen(true);
                setValue(option.value);
            }}
            fullWidth={!md}
            sx={
                !md
                    ? {
                          display: "flex",
                          justifyContent: "space-between",
                      }
                    : {}
            }
        >
            <Typography fontSize={"12px"} color="#000000" marginRight={md ? "16px" : 0}>
                Future emails are set to:{" "}
                <strong
                    style={
                        md
                            ? {}
                            : {
                                  color: "#FF9600",
                              }
                    }
                >
                    {option.label}
                </strong>
            </Typography>
            <ModifyIcon size={16} />
        </Button>
    );
}

function DeleteEmailsButton({ senderEmail, setFutureDeletePreferenceOpen }) {
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const { md } = useCustomMediaQuery();
    if (mailBotEnabledPending) {
        return null;
    }
    const disabled = !mailBotEnabled;
    return (
        <Button
            data-testid={`delete-${senderEmail}`}
            disabled={disabled}
            disableElevation
            onClick={() => {
                setFutureDeletePreferenceOpen(true);
            }}
            sx={{
                background: "rgba(239, 186, 66, 0.1)",
                border: !disabled ? "1px solid #EFBA42" : "",
                borderRadius: "4px",
                width: md ? "132px" : "100px",
                height: md ? "40px" : "30px",
                "&:hover": {
                    background: "#FFAE02",
                },
            }}
            variant="contained"
            startIcon={<DeleteIcon size={16} />}
        >
            <Typography color="#1B1301" variant={md ? "body2" : "caption"}>
                Delete
            </Typography>
        </Button>
    );
}

export default function FuturePreferenceButton({ profile, handleDeleteEmails }) {
    const [userAction, setUserAction] = useState(UserAction.MOVE_TO_TRASH);
    const [futureDeletePreferenceOpen, setFutureDeletePreferenceOpen] = useState(false);
    let option;
    const senderBlockedByEmailZap = !!profile.blocked_by_emailzap;
    const senderBlockedByUser = profile.user_training === "zapped";
    if (profile.user_action_reason === UserActionReason.MARKED_DELETED) {
        if (profile.user_action === UserAction.MOVE_TO_TRASH) {
            option = { label: "Auto delete", value: UserAction.MOVE_TO_TRASH };
        } else if (profile.user_action === UserAction.ARCHIVE) {
            option = { label: "Auto archive", value: UserAction.ARCHIVE };
        } else if (profile.user_action === UserAction.MOVE_TO_INBOX) {
            option = { label: "Deliver to Inbox", value: UserAction.MOVE_TO_INBOX };
        }
    }
    return (
        <>
            {option && (
                <EditDeletedSendersPreferenceButton
                    senderEmail={profile.sender_email}
                    option={option}
                    setFutureDeletePreferenceOpen={setFutureDeletePreferenceOpen}
                    setValue={setUserAction}
                />
            )}
            {option === undefined &&
                (senderBlockedByEmailZap || senderBlockedByUser ? (
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}>
                        <ZappedText title={senderBlockedByEmailZap ? "Zapped by EmailZap" : "Zapped by you"} />
                        <DeleteEmailsButton
                            senderEmail={profile.sender_email}
                            setFutureDeletePreferenceOpen={setFutureDeletePreferenceOpen}
                        />
                    </Box>
                ) : (
                    <DeleteEmailsButton
                        senderEmail={profile.sender_email}
                        setFutureDeletePreferenceOpen={setFutureDeletePreferenceOpen}
                    />
                ))}
            {futureDeletePreferenceOpen && (
                <FutureDeletePreference
                    userAction={userAction}
                    setUserAction={setUserAction}
                    open={futureDeletePreferenceOpen}
                    setOpen={setFutureDeletePreferenceOpen}
                    profile={profile}
                    handleDeleteEmails={handleDeleteEmails}
                />
            )}
        </>
    );
}
