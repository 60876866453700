export default function ModifyIcon({ size = 12 }) {
    return (
        <svg width={size} height={size} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.5 2.00015C8.63132 1.86883 8.78722 1.76466 8.9588 1.69359C9.13038 1.62252 9.31428 1.58594 9.5 1.58594C9.68572 1.58594 9.86961 1.62252 10.0412 1.69359C10.2128 1.76466 10.3687 1.86883 10.5 2.00015C10.6313 2.13147 10.7355 2.28737 10.8066 2.45895C10.8776 2.63054 10.9142 2.81443 10.9142 3.00015C10.9142 3.18587 10.8776 3.36977 10.8066 3.54135C10.7355 3.71293 10.6313 3.86883 10.5 4.00015L3.75 10.7501L1 11.5001L1.75 8.75015L8.5 2.00015Z"
                stroke="black"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
