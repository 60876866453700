import { Box, Tooltip, Typography } from "@mui/material";
import MailInfoIcon from "components/Common/Icons/MailInfoIcon";

type ZappedTextProps = {
    title: "Zapped by EmailZap" | "Zapped by you";
    color?: string;
    iconSize?: number;
};
export default function ZappedText({ title, color = "black", iconSize = 20 }: ZappedTextProps) {
    return (
        <Box display={"flex"} justifyContent="center" alignItems="center" gap={"4px"}>
            <MailInfoIcon size={iconSize} color={color} />
            <Tooltip
                title={`All future emails from this sender will be automatically moved to Zapped label created by Emailzap`}
                enterTouchDelay={0}
                leaveTouchDelay={5000}
            >
                <Typography fontSize={"12px"} fontWeight={500} lineHeight={"14.52px"} color={color}>
                    {title}
                </Typography>
            </Tooltip>
        </Box>
    );
}
