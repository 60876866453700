export default function ChatIcon({ size = 35 }) {
    return (
        <div
            className="rounded-full"
            style={{
                background: "linear-gradient(128.49deg, #FFDC8E 19.86%, #EFBA42 68.34%)",
                padding: size / 5,
                height: size,
                width: size,
            }}
        >
            <svg
                width={size * 0.6}
                height={size * 0.66}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.33268 15H4.99935V18.4008L9.25018 15H13.3327C14.2518 15 14.9993 14.2525 14.9993 13.3333V6.66667C14.9993 5.7475 14.2518 5 13.3327 5H3.33268C2.41352 5 1.66602 5.7475 1.66602 6.66667V13.3333C1.66602 14.2525 2.41352 15 3.33268 15Z"
                    fill="white"
                />
                <path
                    d="M16.6667 1.6665H6.66667C5.7475 1.6665 5 2.414 5 3.33317H15C15.9192 3.33317 16.6667 4.08067 16.6667 4.99984V11.6665C17.5858 11.6665 18.3333 10.919 18.3333 9.99984V3.33317C18.3333 2.414 17.5858 1.6665 16.6667 1.6665Z"
                    fill="white"
                />
            </svg>
        </div>
    );
}
