import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { Box, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import EditIcon from "components/Common/Icons/EditIcon";
import Loader from "components/Common/Loader";
import UndoButton from "components/Common/UndoButton";
import { UserAction, UserActionReason } from "defines";
import { useConfigureSenderWorkflows } from "hooks/api/mailbot/mutations";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { SnackbarMessageContext } from "layouts/AppLayout";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useContext } from "react";

function CustomRuleButton({ senderEmail, props }) {
    const { md } = useCustomMediaQuery();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    if (mailBotEnabledPending) {
        return null;
    }
    const disabled = !mailBotEnabled;
    return (
        <Button
            {...props}
            data-testid={`custom-rule-${senderEmail}`}
            disableElevation
            disabled={disabled}
            variant="contained"
            sx={{
                width: "100%",
                background: "#EFBA421A",
                height: md ? "40px" : "30px",
                border: !disabled && "1px solid var(--Yellow, #EFBA42)",
            }}
            startIcon={<EditIcon size={md ? 20 : 16} />}
        >
            <Typography color="#000000" lineHeight={"16.94px"} variant={md ? "body2" : "caption"}>
                {!md ? "Custom rule" : "Add custom rule"}
            </Typography>
        </Button>
    );
}

const CustomRuleMapping = {
    [UserAction.MARK_AS_STARRED]: "Mark as starred",
    [UserAction.ARCHIVE]: "Archive",
    [UserAction.MOVE_TO_TRASH]: "Move to trash",
    [UserAction.MARK_AS_READ]: "Mark as read",
    [UserAction.MARK_AS_SPAM]: "Mark as spam",
};

interface ICustomRuleOption {
    label: string;
    value: string;
    renderIcon: any;
    dividerBottom?: boolean;
}

const CUSTOM_RULE_OPTIONS: ICustomRuleOption[] = [
    {
        label: UserAction.MARK_AS_SPAM,
        value: "Move to spam",
        renderIcon: () => <ReportOutlinedIcon />,
    },
    {
        label: UserAction.ARCHIVE,
        value: "Archive",
        renderIcon: () => <ArchiveOutlinedIcon />,
        dividerBottom: true,
    },
    {
        label: UserAction.MARK_AS_READ,
        value: "Mark as read",
        renderIcon: () => <MarkEmailReadOutlinedIcon />,
    },
    {
        label: UserAction.MARK_AS_STARRED,
        value: "Mark as starred",
        renderIcon: () => <StarBorderOutlinedIcon />,
    },
];

function CustomRulesMenu({ senderEmail, options, onClick }) {
    const popupState = usePopupState({ variant: "popover", popupId: "custom-rules-menu" });
    const { md } = useCustomMediaQuery();
    return (
        <>
            <CustomRuleButton senderEmail={senderEmail} props={bindTrigger(popupState)} />
            <Menu
                {...bindMenu(popupState)}
                slotProps={{
                    paper: {
                        sx: {
                            width: md ? "250px" : "200px",
                            px: 1,
                            borderRadius: "2px",
                        },
                    },
                }}
            >
                <Box
                    display={"flex"}
                    gap={"2px"}
                    sx={{
                        p: 1,
                        background: "#EFBA421A",
                        borderRadius: "2px",
                    }}
                >
                    <InfoOutlinedIcon sx={{ color: "#000000" }} />
                    <Typography color="#000000" lineHeight={"14.52px"} variant={"caption"}>
                        All these changes will apply to future emails from this sender
                    </Typography>
                </Box>
                {options.map((option) => (
                    <Box key={option.label}>
                        <MenuItem
                            data-testid={`custom-rule-${option.label}-${senderEmail}`}
                            onClick={() => {
                                onClick(option.label);
                                popupState.close();
                            }}
                        >
                            <ListItemIcon>{option.renderIcon()}</ListItemIcon>
                            <ListItemText>{option.value}</ListItemText>
                        </MenuItem>
                        {option.dividerBottom && <Divider />}
                    </Box>
                ))}
            </Menu>
        </>
    );
}

export const CustomRule = ({ profile, ruleEnabled = false }) => {
    const configureSenderWorkflowsMutation = useConfigureSenderWorkflows();
    const { setSuccessMessage, setSuccessMessageTitle } = useContext(SnackbarMessageContext);
    // Event handlers
    const onRuleConfigure = (label: string) => {
        configureSenderWorkflowsMutation.mutate(
            {
                senderProfileId: profile.id,
                action: label,
                actionReason: UserActionReason.RULE_SELECTED,
            },
            {
                onSuccess: () => {
                    let action;
                    if (label === UserAction.MARK_AS_SPAM) {
                        action = "Delivered to spam";
                    } else if (label === UserAction.MARK_AS_READ) {
                        action = "Marked as read";
                    } else if (label === UserAction.ARCHIVE) {
                        action = "archived";
                    } else if (label === UserAction.MARK_AS_STARRED) {
                        action = "Marked as starred";
                    }
                    setSuccessMessageTitle("Custom rule setup successfully");
                    setSuccessMessage(`All future emails from this sender will be ${action}`);
                },
            },
        );
    };

    return (
        <>
            {ruleEnabled && (
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}>
                    <Box display={"flex"} justifyContent="center" alignItems="center" gap={"4px"}>
                        {CUSTOM_RULE_OPTIONS.find((option) => option.label === profile.user_action)?.renderIcon()}
                        <Typography fontSize={"12px"} fontWeight={500} lineHeight={"14.52px"} color="#000000">
                            {CustomRuleMapping[profile.user_action]}
                        </Typography>
                    </Box>
                    <UndoButton undoCustomRule={true} profile={profile} />
                </Box>
            )}
            {configureSenderWorkflowsMutation.isPending && <Loader />}
            {!ruleEnabled && (
                <CustomRulesMenu
                    senderEmail={profile.sender_email}
                    options={CUSTOM_RULE_OPTIONS}
                    onClick={onRuleConfigure}
                />
            )}
        </>
    );
};
