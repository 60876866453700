// TODO: Update APPLICATION_TAGS key from zap to bot
export const APPLICATION_TAGS = {
    EMAILZAP: "mailbot",
    CALENDARZAP: "calendarbot",
    REMINDERZAP: "reminderbot",
    STORAGEZAP: "storagebot",
};

export enum MAILBOT_FEATURE_FLAGS {
    EMAILZAP = "email_zap",
    DIGEST_EMAIL = "digest_email",
    AUTO_ARCHIVAL = "auto-archival",
    FIRST_TIME_SENDER_OVERLAY = "first-time-sender-overlay",
    AI_AUTO_RESPONDER = "ai-auto-responder",
    JARVIS = "jarvis",
    SECONDARY_PROFILES = "secondary-profiles",
    ZAPPED_LABEL = "zapped-label",
    BULK_DELETE = "bulk-delete",
    UNSUBSCRIBE = "unsubscribe",
}

export enum SubscriptionStatus {
    // Active subscription states
    STATUS_TRIALING = "trialing",
    STATUS_ACTIVE = "active",
    STATUS_PAST_DUE = "past_due", // Grace period
    // Inactive subscription states
    STATUS_INCOMPLETE = "incomplete", // Payment failed or incomplete but stripe is still trying
    STATUS_INCOMPLETE_EXPIRED = "incomplete_expired", // Payment failed or incomplete and stripe has stopped trying
    STATUS_CANCELED = "canceled", // User canceled subscription
    STATUS_UNPAID = "unpaid", // User has not paid (not used currently)
}

export const ACTIVE_SUBSCRIPTION_STATUSES = [
    SubscriptionStatus.STATUS_TRIALING,
    SubscriptionStatus.STATUS_ACTIVE,
    SubscriptionStatus.STATUS_PAST_DUE,
];

export const INACTIVE_SUBSCRIPTION_STATUSES = [
    SubscriptionStatus.STATUS_INCOMPLETE,
    SubscriptionStatus.STATUS_INCOMPLETE_EXPIRED,
    SubscriptionStatus.STATUS_CANCELED,
    SubscriptionStatus.STATUS_UNPAID,
];

export enum UserAction {
    MARK_AS_SPAM = "mark_as_spam",
    MARK_AS_READ = "mark_as_read",
    MARK_AS_STARRED = "mark_as_starred",
    ARCHIVE = "archive",
    MOVE_TO_TRASH = "move_to_trash",
    MOVE_TO_INBOX = "move_to_inbox",
}

export enum UserActionReason {
    UNSUBSCRIBED = "unsubscribed",
    MARKED_DELETED = "marked_deleted",
    RULE_SELECTED = "rule_selected",
}

export const maxContentWidthDesktop = "1116px";
