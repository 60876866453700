import { Box, Button, ButtonGroup, Link, Paper, Typography } from "@mui/material";
import EmailZapIcon from "components/Common/Icons/EmailZapIcon";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { useLocation } from "react-router-dom";
import { getAllQueryParams } from "utils";
import { loginMailBotProfile } from "utils/login";

export default function Login() {
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const trackingParameters = getAllQueryParams(params);
    const { md } = useCustomMediaQuery();
    if (md) {
        return (
            <Box height={"100vh"} width={"100vw"}>
                <Box
                    sx={{
                        background: "#0D0E23",
                        height: "12.75%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <EmailZapIcon size={50} />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        backgroundImage: "url(/images/login/background.png)",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: "87.25%",
                        width: "100%",
                    }}
                >
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
                        <Paper
                            style={{
                                border: "6px solid #FFFFFF",
                                width: "800px",
                                height: "424px",
                                borderRadius: "16px",
                                background: "linear-gradient(180deg, #FBFAFB 0%, #FFFFFF 100%)",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                width={"424px"}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                justifyContent={"space-around"}
                                height={"100%"}
                            >
                                <Box>
                                    <Typography
                                        variant="h4"
                                        fontWeight={600}
                                        color="text.primary"
                                        align="center"
                                        gutterBottom
                                    >
                                        Welcome Back
                                    </Typography>
                                    <Typography
                                        color="text.secondary"
                                        align="center"
                                        sx={{
                                            opacity: 0.5,
                                        }}
                                    >
                                        Sign in to set your preferences or see stats on those who send you emails
                                    </Typography>
                                </Box>
                                <ButtonGroup disableElevation disableRipple orientation="vertical">
                                    <Button
                                        onClick={() => loginMailBotProfile("google", trackingParameters)}
                                        style={{
                                            width: "424px",
                                            height: "48px",
                                            border: "1px solid #E7E7E7",
                                            borderRadius: "8px",
                                            marginBottom: "24px",
                                        }}
                                        color="inherit"
                                        startIcon={
                                            <img className="w-5" src="/images/gmail_logo.png" alt="Gmail Logo" />
                                        }
                                    >
                                        Continue with Gmail
                                    </Button>
                                    <Button
                                        style={{
                                            width: "424px",
                                            height: "48px",
                                            border: "1px solid #E7E7E7",
                                            borderRadius: "8px",
                                        }}
                                        color="inherit"
                                        onClick={() => loginMailBotProfile("microsoft", trackingParameters)}
                                        startIcon={
                                            <img className="w-5" src="/images/outlook_logo.png" alt="Outlook logo" />
                                        }
                                    >
                                        Continue with Outlook
                                    </Button>
                                </ButtonGroup>
                                <Typography variant="body2" color="#808080">
                                    Having trouble? Contact us at{" "}
                                    <Link color="#EFBA42" href="mailto:support@emailzap.co" fontWeight={600}>
                                        support@emailzap.co
                                    </Link>
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Box>
        );
    } else {
        return (
            <Box height={"100vh"} width={"100vw"}>
                <Box
                    sx={{
                        background: "#0D0E23",
                        height: "10.42%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <EmailZapIcon />
                </Box>
                <Box
                    sx={{
                        backgroundImage: "url(/images/login/mobile_background.png)",
                        backgroundPosition: "center",
                        backgroundSize: "auto 100%",
                        backgroundColor: "#fafafa",
                        backgroundRepeat: "no-repeat",
                        height: "89.58%",
                        width: "100%",
                    }}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"space-around"}
                        height={"100%"}
                    >
                        <Box
                            maxWidth={"343px"}
                            width={"100%"}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                        >
                            <Box marginBottom={"3.7vh"} width={"100%"}>
                                <Typography
                                    fontSize={"28px"}
                                    fontWeight={600}
                                    color="text.primary"
                                    align="center"
                                    gutterBottom
                                    marginTop={"23.17vh"}
                                >
                                    Welcome Back
                                </Typography>
                                <Typography
                                    color="text.secondary"
                                    align="center"
                                    variant="subtitle2"
                                    sx={{
                                        opacity: 0.5,
                                    }}
                                >
                                    Sign in to set your preferences or see stats on those who send you emails
                                </Typography>
                            </Box>
                            <Box width={"100%"} p={1}>
                                <ButtonGroup fullWidth disableElevation disableRipple orientation="vertical">
                                    <Button
                                        onClick={() => loginMailBotProfile("google", trackingParameters)}
                                        fullWidth
                                        style={{
                                            height: "48px",
                                            border: "1px solid #E7E7E7",
                                            borderRadius: "8px",
                                            marginBottom: "24px",
                                            background: "white",
                                        }}
                                        color="inherit"
                                        startIcon={
                                            <img className="w-5" src="/images/gmail_logo.png" alt="Gmail Logo" />
                                        }
                                    >
                                        Continue with Gmail
                                    </Button>
                                    <Button
                                        fullWidth
                                        style={{
                                            height: "48px",
                                            border: "1px solid #E7E7E7",
                                            borderRadius: "8px",
                                            background: "white",
                                        }}
                                        color="inherit"
                                        onClick={() => loginMailBotProfile("microsoft", trackingParameters)}
                                        startIcon={
                                            <img className="w-5" src="/images/outlook_logo.png" alt="Outlook logo" />
                                        }
                                    >
                                        Continue with Outlook
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Box>
                        <Box maxWidth={"350px"} width={"100%"}>
                            <Typography variant="body2" color="#808080" align="center">
                                Having trouble? Contact us at{" "}
                                <Link color="#EFBA42" href="mailto:support@emailzap.co" fontWeight={600}>
                                    support@emailzap.co
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
}
