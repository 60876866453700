import { useQuery } from "@tanstack/react-query";
import { mailbotKeys } from "queryKeyFactory";
import {
    getCanMailBotDisabled,
    getCanMailBotEnabled,
    getIsMailBotActive,
    getMailBotPreferences,
    getStatistics,
    getHistoricalEmailsCount,
} from "services/mailbot/queries";

export function useMailBot(select: any = undefined) {
    return useQuery({
        queryKey: mailbotKeys.preferences(),
        queryFn: getMailBotPreferences,
        select: select,
    });
}

export function useMailBotPreferences() {
    return useMailBot((data) => data.preferences);
}

export function useMailBotMetadata() {
    return useMailBot((data) => data.metadata);
}

export function useMailBotProfileId() {
    return useMailBot((data) => data.id);
}

export function useIsPrimaryProfile() {
    return useMailBot((data) => data.primary);
}

export function useMailBotStatistics() {
    return useQuery({
        staleTime: 0, // Always fetch the latest data for statistics as it is used in the dashboard
        queryKey: mailbotKeys.statistics(),
        queryFn: getStatistics,
    });
}

export function useMailBotServiceProvider() {
    return useMailBot((data) => data.service_provider);
}

export function useCanMailBotDisabled() {
    return useQuery({
        queryKey: mailbotKeys.canDisable(),
        queryFn: getCanMailBotDisabled,
    });
}

export function useCanMailBotEnabled() {
    return useQuery({
        queryKey: mailbotKeys.canEnable(),
        queryFn: getCanMailBotEnabled,
    });
}

export function useIsMailBotActive() {
    return useQuery({
        queryKey: mailbotKeys.isActive(),
        queryFn: getIsMailBotActive,
    });
}

export function useHistoricalEmailsCount({ senderEmail, enabled }) {
    return useQuery({
        queryKey: mailbotKeys.historicalEmailsCount({ senderEmail }),
        queryFn: () => getHistoricalEmailsCount({ senderEmail }),
        enabled,
    });
}
