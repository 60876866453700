import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

/**
 * The Snackbar component appears temporarily and floats above the UI to provide users with (non-critical) updates on an app's processes.
 */
export default function SnackBar({ open, handleClose, severity, message, autoHideDuration = 5000, title = "" }) {
    const { md } = useCustomMediaQuery();
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: md ? "right" : "center" }}
            ContentProps={{
                sx: {
                    maxWidth: "450px",
                    background: "#FDFDFD",
                    color: "#1C1D31",
                    fontWeight: 500,
                },
                style: {
                    maxWidth: "450px",
                    background: "#FDFDFD",
                    color: "#1C1D31",
                    fontWeight: 500,
                },
            }}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                icon={false}
                sx={{
                    maxWidth: "450px",
                }}
            >
                {title.length > 0 && <AlertTitle>{title}</AlertTitle>}
                {message}
            </Alert>
        </Snackbar>
    );
}
