import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import Loader from "components/Common/Loader";
import { useCancelSubscription } from "hooks/api/payments/mutations";
import { useState } from "react";
import SnackBar from "components/Common/Snackbar";

enum CancellationReason {
    TOO_EXPENSIVE = "Too expensive",
    DIDNT_WORK = "It didn't work as expected",
    NO_BLOCKING = "I didn't want emailzap blocking anyone on their own",
    CONFUSING = "It was confusing",
    NEED_FEATURES = "Need more features",
    OTHER = "Other",
}

// We want to show cancellation reasons in random order to avoid bias in selection, but always show "OTHER" at the end
const randomCancellationReasons = Object.values(CancellationReason).sort((a, b) => {
    if (a === CancellationReason.OTHER) {
        return 1;
    }
    if (b === CancellationReason.OTHER) {
        return -1;
    }
    return Math.random() - 0.5;
});

export default function SubscriptionCancellation({ subscription, open, setOpen }) {
    const cancelSubscription = useCancelSubscription();
    const [cancellationReason, setCancellationReason] = useState<string[]>([]);
    const [cancelationText, setCancelationText] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const handleCancelSubscription = () => {
        setOpen(false);
        cancelSubscription.mutate(
            {
                id: subscription.id,
                reason: cancellationReason,
                reasonText: cancelationText,
            },
            {
                onSuccess: () => {
                    // Show success message
                    setSuccessMessage("Subscription cancelled successfully");
                    setCancellationReason([]);
                    setCancelationText("");
                },
                onError: () => {
                    // Show error message
                    setErrorMessage("Error occured while cancelling your subscription. Please try again.");
                    setCancellationReason([]);
                    setCancelationText("");
                },
            },
        );
    };

    if (cancelSubscription.isPending) {
        return <Loader />;
    }
    const isTextReasonRequired =
        cancellationReason.filter(
            (x) =>
                x === CancellationReason.OTHER ||
                x === CancellationReason.NEED_FEATURES ||
                x === CancellationReason.DIDNT_WORK,
        ).length > 0;
    const allowCancelation = cancellationReason.length > 0 && (!isTextReasonRequired || cancelationText.length > 0);
    return (
        <Box>
            <Dialog
                open={open}
                onClose={() => {
                    setCancellationReason([]);
                    setCancelationText("");
                    setOpen(false);
                }}
            >
                <DialogTitle>Cancel subscription</DialogTitle>
                <DialogContent>
                    <DialogContentText gutterBottom>
                        We are really sad to see you cancel you subscription. We hope to see you back later.
                    </DialogContentText>
                    <FormGroup>
                        {randomCancellationReasons.map((reason) => (
                            <Box key={reason}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={cancellationReason.includes(reason)}
                                            onChange={(e) =>
                                                setCancellationReason((prev) => {
                                                    if (e.target.checked) {
                                                        return [...prev, reason];
                                                    }
                                                    return prev.filter((x) => x !== reason);
                                                })
                                            }
                                        />
                                    }
                                    label={reason}
                                />
                            </Box>
                        ))}
                    </FormGroup>
                    {isTextReasonRequired && (
                        <Box width={"100%"}>
                            <Typography>Please specify</Typography>
                            <textarea
                                data-testid="cancel-subscription-reason-text"
                                rows={3}
                                className="w-full"
                                maxLength={300}
                                placeholder="Please specify the reason for cancellation"
                                value={cancelationText}
                                onChange={(e) => setCancelationText(e.target.value)}
                            />
                        </Box>
                    )}
                    {allowCancelation && (
                        <Box display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                disableElevation
                                onClick={handleCancelSubscription}
                                data-testid="cancel-subscription-confirm"
                            >
                                Confirm cancellation
                            </Button>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
            <SnackBar
                open={successMessage.length > 0}
                handleClose={() => setSuccessMessage("")}
                severity="success"
                message={successMessage}
                autoHideDuration={5000}
            />
            <SnackBar
                open={errorMessage.length > 0}
                handleClose={() => setErrorMessage("")}
                severity="error"
                message={errorMessage}
                autoHideDuration={5000}
            />
        </Box>
    );
}
