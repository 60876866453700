import { OrderDirection } from "types/maintypes";

export const formatExpirationTime = (expirationTime: number) => {
    const currentTime = new Date().getTime() / 1000;

    let d = Number(expirationTime - currentTime);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes ") : "";
    return hDisplay + mDisplay;
};

export const getAllQueryParams = (searchParams: URLSearchParams) => {
    const params: Record<string, string> = {};
    for (const [key, value] of searchParams.entries()) {
        params[key] = value;
    }
    return params;
};

export const formatOrderingString = (fieldName: string, order: OrderDirection): string => {
    const direction = order === "asc" ? "" : "-";
    return `ordering=${direction}${fieldName}`;
};

export const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const isSubset = <T>(subset: T[], superset: T[]): boolean =>
    subset.every((element) => superset.includes(element));
