import { Box, Button, ButtonGroup, Divider, Link, Paper, Typography } from "@mui/material";
import EmailZapIcon from "components/Common/Icons/EmailZapIcon";
import { useMailBotOperations } from "hooks/api/mailbot/mutations";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAllQueryParams } from "utils";
import { loginMailBotProfile } from "utils/login";

enum Status {
    SUCCESS,
    FAILURE,
    LOADING,
}

enum DisplayStatus {
    ORIGINAL_MESSAGE_NOT_FOUND = 1,
    MESSAGE_ALREADY_MOVED_TO_LABEL = 2,
    PREFERENCE_ALREADY_UPDATED = 3,
    ORIGINAL_MESSAGE_IN_TRASH = 4,
    MESSAGE_MOVED_SUCCESS = 5,
    DOMAIN_TRAINING_SUCCESS = 6,
    UNDO_DOMAIN_TRAINING_SUCCESS = 7,
    USER_TRAINING_SUCCESS = 8,
    UNDO_USER_TRAINING_SUCCESS = 9,
    DOMAIN_PREFERENCE_UPDATE_SUCCESS = 10,
    UNDO_DOMAIN_PREFERENCE_UPDATE_SUCCESS = 11,
    SENDER_AUTO_ARCHIVE_PREFERENCE_UPDATE_SUCCESS = 12,
    DOMAIN_AUTO_ARCHIVE_PREFERENCE_UPDATE_SUCCESS = 13,
    OPERATION_NOT_SUPPORTED = 14,
    AUTO_ARCHIVE_CANCEL_SUCCESS = 15,
}

function getDisplayMessage(displayStatus: DisplayStatus, context: { [key: string]: string }): string {
    switch (displayStatus) {
        // Email due to which mailbot sent an alert is not found in the user's mailbox
        case DisplayStatus.ORIGINAL_MESSAGE_NOT_FOUND:
            return "Hi, there is some error processing this email. Can you try this operation on a latest email.";
        // Message is already present in the label to which user is trying to move it (digest, lucene alert, ftso, etc.)
        case DisplayStatus.MESSAGE_ALREADY_MOVED_TO_LABEL:
            return `Message has already been moved to ${context.label_name}.`;
        case DisplayStatus.PREFERENCE_ALREADY_UPDATED:
            return "Your preference is already updated.";
        // Message moved to TRASH cannot be moved to any other label
        case DisplayStatus.ORIGINAL_MESSAGE_IN_TRASH:
            return `A deleted email cannot be moved by Emailzap . You need to first move the email out of ${context.label_name} and then perform this operation.`;
        case DisplayStatus.MESSAGE_MOVED_SUCCESS:
            return `Message moved to ${context.label_name} successfully.`;
        case DisplayStatus.DOMAIN_TRAINING_SUCCESS:
            return `Sure, We will send all mails from ${context.sender_domain} to ${context.label_name} in the future.`;
        case DisplayStatus.UNDO_DOMAIN_TRAINING_SUCCESS:
            return `The operation has been reverted.`;
        case DisplayStatus.USER_TRAINING_SUCCESS:
            return `Sure, We will send all mails from ${context.sender_email} to ${context.label_name} in the future.`;
        case DisplayStatus.UNDO_USER_TRAINING_SUCCESS:
            return `The operation has been reverted.`;
        case DisplayStatus.DOMAIN_PREFERENCE_UPDATE_SUCCESS:
            return `Sure, We will continue to send these alerts from ${context.sender_domain} in future.`;
        case DisplayStatus.UNDO_DOMAIN_PREFERENCE_UPDATE_SUCCESS:
            return `Sure, We will not send these alerts from ${context.sender_domain} in future.`;
        case DisplayStatus.SENDER_AUTO_ARCHIVE_PREFERENCE_UPDATE_SUCCESS:
            return `Sure, We will not archive these emails from ${context.sender_email} anymore.`;
        case DisplayStatus.DOMAIN_AUTO_ARCHIVE_PREFERENCE_UPDATE_SUCCESS:
            return `Sure, We will not archive these emails from ${context.sender_domain} anymore.`;
        case DisplayStatus.AUTO_ARCHIVE_CANCEL_SUCCESS:
            return "Sure, We will not archive this email.";
        case DisplayStatus.OPERATION_NOT_SUPPORTED:
            return "This operation is not supported yet.";
        default:
            return "Something went wrong.";
    }
}

function MailOperations({ isUserLoggedIn }) {
    const search = useLocation().search;
    const mailBotOperationsMutation = useMailBotOperations();
    const [displayMessage, setDisplayMessage] = useState("");
    const [status, setStatus] = useState(Status.LOADING);
    const { md } = useCustomMediaQuery();

    const invokeBackend = (params) => {
        mailBotOperationsMutation.mutate(
            { params },
            {
                onSuccess: (res) => {
                    setStatus(Status.SUCCESS);
                    const mail_operation_status = res.mail_operation_status;
                    const mail_operation_context = res.mail_operation_context;
                    setDisplayMessage(getDisplayMessage(mail_operation_status, mail_operation_context));
                },
                onError: (err: any) => {
                    setDisplayMessage(err.response.data["detail"]);
                    setStatus(Status.FAILURE);
                },
            },
        );
    };
    let heading = "Loading...";
    if (status === Status.SUCCESS) heading = "Success!";
    else if (status === Status.FAILURE) heading = "Failed!";

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const params = getAllQueryParams(searchParams);
        invokeBackend(params);
    }, []);
    if (md) {
        return (
            <Box
                width={"100%"}
                height={"100vh"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                sx={{
                    background: "#F9FAFB",
                }}
            >
                <Box
                    width={"916px"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-around"}
                    height={"100vh"}
                    alignItems={"center"}
                >
                    <Box width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        <Box marginBottom={"32px"}>
                            <EmailZapIcon darkBackground={false} size={65} />
                        </Box>
                        <Paper
                            sx={{
                                width: "800px",
                                height: "150px",
                                border: "6px solid #FFFFFF",
                                borderRadius: "16px",
                                background: "linear-gradient(180deg, #FBFAFB 0%, #FFFFFF 100%)",
                                px: "100px",
                                py: "37.5px",
                                marginBottom: "110px",
                            }}
                        >
                            <Typography fontSize={"32px"} fontWeight={700} lineHeight={"40px"} align="center">
                                {heading}
                            </Typography>
                            <Typography align="center">{displayMessage}</Typography>
                        </Paper>
                        <Divider
                            sx={{
                                width: "100%",
                                border: "1px solid #D2D9E2",
                            }}
                        />
                        {!isUserLoggedIn && (
                            <>
                                <Box
                                    display={"flex"}
                                    gap={"16px"}
                                    alignItems={"center"}
                                    marginTop={"75px"}
                                    marginBottom={"45px"}
                                >
                                    <Typography fontWeight={500}>Continue to</Typography>
                                    <EmailZapIcon size={48} darkBackground={false} />
                                </Box>
                                <ButtonGroup disableElevation disableRipple orientation="vertical">
                                    <Button
                                        onClick={() => loginMailBotProfile("google")}
                                        style={{
                                            width: "424px",
                                            height: "48px",
                                            border: "1px solid #E7E7E7",
                                            borderRadius: "8px",
                                            marginBottom: "24px",
                                        }}
                                        color="inherit"
                                        startIcon={
                                            <img className="w-5" src="/images/gmail_logo.png" alt="Gmail Logo" />
                                        }
                                    >
                                        Continue with Gmail
                                    </Button>
                                    <Button
                                        style={{
                                            width: "424px",
                                            height: "48px",
                                            border: "1px solid #E7E7E7",
                                            borderRadius: "8px",
                                        }}
                                        color="inherit"
                                        onClick={() => loginMailBotProfile("microsoft")}
                                        startIcon={
                                            <img className="w-5" src="/images/outlook_logo.png" alt="Outlook logo" />
                                        }
                                    >
                                        Continue with Outlook
                                    </Button>
                                </ButtonGroup>
                            </>
                        )}
                    </Box>
                    <Typography variant="body2" color="#808080">
                        Having trouble? Contact us at{" "}
                        <Link color="#EFBA42" href="mailto:support@emailzap.co" fontWeight={600}>
                            support@emailzap.co
                        </Link>
                    </Typography>
                </Box>
            </Box>
        );
    } else {
        return (
            <Box height={"100vh"} width={"100vw"}>
                <Box
                    p={2}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        width={"100%"}
                    >
                        <Box marginBottom="32px">
                            <EmailZapIcon size={52} darkBackground={false} />
                        </Box>
                        <Paper
                            sx={{
                                width: "100%",
                                border: "6px solid #FFFFFF",
                                borderRadius: "16px",
                                background: "linear-gradient(180deg, #FBFAFB 0%, #FFFFFF 100%)",
                                px: "40px",
                                py: "34px",
                                marginBottom: "32px",
                            }}
                        >
                            <Typography fontSize={"28px"} fontWeight={700} lineHeight={"40px"} align="center">
                                {heading}
                            </Typography>
                            <Typography variant="body1" align="center">
                                {displayMessage}
                            </Typography>
                        </Paper>
                        <Divider
                            sx={{
                                width: "100%",
                                border: "1px solid #D2D9E2",
                            }}
                        />
                        {!isUserLoggedIn && (
                            <>
                                <Box
                                    display={"flex"}
                                    gap={"16px"}
                                    alignItems={"center"}
                                    marginTop={"32px"}
                                    marginBottom={"45px"}
                                >
                                    <Typography fontWeight={500}>Continue to</Typography>
                                    <EmailZapIcon size={52} darkBackground={false} />
                                </Box>
                                <ButtonGroup fullWidth disableElevation disableRipple orientation="vertical">
                                    <Button
                                        onClick={() => loginMailBotProfile("google")}
                                        style={{
                                            height: "48px",
                                            border: "1px solid #E7E7E7",
                                            borderRadius: "8px",
                                            marginBottom: "24px",
                                        }}
                                        color="inherit"
                                        startIcon={
                                            <img className="w-5" src="/images/gmail_logo.png" alt="Gmail Logo" />
                                        }
                                    >
                                        Continue with Gmail
                                    </Button>
                                    <Button
                                        style={{
                                            height: "48px",
                                            border: "1px solid #E7E7E7",
                                            borderRadius: "8px",
                                        }}
                                        color="inherit"
                                        onClick={() => loginMailBotProfile("microsoft")}
                                        startIcon={
                                            <img className="w-5" src="/images/outlook_logo.png" alt="Outlook logo" />
                                        }
                                    >
                                        Continue with Outlook
                                    </Button>
                                </ButtonGroup>
                            </>
                        )}
                    </Box>
                    <Box>
                        <Typography variant="body2" color="#808080" align="center">
                            Having trouble? Contact us at{" "}
                            <Link color="#EFBA42" href="mailto:support@emailzap.co" fontWeight={600}>
                                support@emailzap.co
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default MailOperations;
