import SquareIcon from "@mui/icons-material/Square";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FeatureDetailedInfoCard({ title, description }) {
    return (
        <Accordion
            disableGutters
            variant="outlined"
            defaultExpanded={true}
            sx={{
                borderColor: "#D2D9E2",
                width: "100%",
                background: "linear-gradient(180deg, rgba(230, 255, 207, 0.1) 0%, rgba(239, 186, 66, 0.1) 100%)",
                borderRadius: "2px",
                "& .MuiAccordionSummary-root": {
                    minHeight: "100%",
                },
                "& .MuiAccordionSummary-content": {
                    my: 1,
                    mx: 0,
                },
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={600} lineHeight={"16.94px"} variant="subtitle2">
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "space-between" }}>
                    {description.map((desc, index) => (
                        <Box sx={{ display: "flex", gap: "8px", alignItems: "baseline" }} key={index}>
                            <SquareIcon sx={{ color: "#EFBA42", fontSize: "12px" }} />
                            <Typography variant="body2" lineHeight={"16.94px"}>
                                {desc}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
