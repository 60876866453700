export default function UnsubscribeIcon({ size = 14, color = "black" }) {
    return (
        <svg width={size} height={size} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.9997 3.33268C12.8229 3.33268 12.6533 3.40292 12.5283 3.52794C12.4032 3.65297 12.333 3.82254 12.333 3.99935V9.33268C12.333 9.50949 12.2628 9.67906 12.1377 9.80408C12.0127 9.92911 11.8432 9.99935 11.6663 9.99935H2.33301C2.1562 9.99935 1.98663 9.92911 1.8616 9.80408C1.73658 9.67906 1.66634 9.50949 1.66634 9.33268V2.93935L5.58634 6.85935C5.96134 7.23388 6.46967 7.44425 6.99967 7.44425C7.52968 7.44425 8.03801 7.23388 8.41301 6.85935L10.8063 4.47268C10.9319 4.34715 11.0024 4.17688 11.0024 3.99935C11.0024 3.82181 10.9319 3.65155 10.8063 3.52601C10.6808 3.40048 10.5105 3.32995 10.333 3.32995C10.1555 3.32995 9.98521 3.40048 9.85967 3.52601L7.47301 5.91935C7.41103 5.98183 7.3373 6.03143 7.25606 6.06528C7.17482 6.09912 7.08768 6.11655 6.99967 6.11655C6.91167 6.11655 6.82453 6.09912 6.74329 6.06528C6.66205 6.03143 6.58832 5.98183 6.52634 5.91935L2.60634 1.99935H7.66634C7.84315 1.99935 8.01272 1.92911 8.13774 1.80409C8.26277 1.67906 8.33301 1.50949 8.33301 1.33268C8.33301 1.15587 8.26277 0.986302 8.13774 0.861278C8.01272 0.736254 7.84315 0.666016 7.66634 0.666016H2.33301C1.80257 0.666016 1.29387 0.876729 0.918794 1.2518C0.543722 1.62687 0.333008 2.13558 0.333008 2.66602V9.33268C0.333008 9.86311 0.543722 10.3718 0.918794 10.7469C1.29387 11.122 1.80257 11.3327 2.33301 11.3327H11.6663C12.1968 11.3327 12.7055 11.122 13.0806 10.7469C13.4556 10.3718 13.6663 9.86311 13.6663 9.33268V3.99935C13.6663 3.82254 13.5961 3.65297 13.4711 3.52794C13.3461 3.40292 13.1765 3.33268 12.9997 3.33268ZM10.333 1.99935H12.9997C13.1765 1.99935 13.3461 1.92911 13.4711 1.80409C13.5961 1.67906 13.6663 1.50949 13.6663 1.33268C13.6663 1.15587 13.5961 0.986302 13.4711 0.861278C13.3461 0.736254 13.1765 0.666016 12.9997 0.666016H10.333C10.1562 0.666016 9.98663 0.736254 9.8616 0.861278C9.73658 0.986302 9.66634 1.15587 9.66634 1.33268C9.66634 1.50949 9.73658 1.67906 9.8616 1.80409C9.98663 1.92911 10.1562 1.99935 10.333 1.99935Z"
                fill={color}
            />
        </svg>
    );
}
