import { Box, Typography } from "@mui/material";
import BuySubscription from "components/Common/BuySubscription";
import FeatureDetailedInfoCard from "components/Common/FeatureDetailedInfoCard";
import Loader from "components/Common/Loader";
import MobileTabs from "components/Common/MobileTabs";
import DeleteEmailsCardList from "components/DeleteEmails/DeleteEmailsCard";
import DeleteEmailsTable from "components/DeleteEmails/DeleteEmailsTable";
import { maxContentWidthDesktop } from "defines";
import { useMailBotServiceProvider } from "hooks/api/mailbot/queries";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

const featureTitle = "How delete functionality works";

export default function DeleteEmails() {
    const { md } = useCustomMediaQuery();
    const { data: subscription, isPending } = useLatestSubscription();
    const { data: serviceProvider, isPending: serviceProviderPending } = useMailBotServiceProvider();
    if (isPending || serviceProviderPending) {
        return <Loader />;
    }
    const featureDescription = [
        `When you hit "Delete", EmailZap will move all emails from that sender to the ${serviceProvider === "google" ? "Trash folder of gmail" : "Trash folder of outlook"}. ${serviceProvider === "google" ? "Gmail" : "Outlook"} permanently deletes emails from Trash in 30 days`,
        `While deleting emails via EmailZap, You can also specify where should future emails be sent from that sender. You can set them to get auto-deleted as well`,
        `Do note that deleting emails is not reversible. So, use this option carefully.`,
    ];
    return (
        <Box>
            {!md && <MobileTabs />}
            <Box
                marginLeft={"auto"}
                marginRight={"auto"}
                marginBottom={"32px"}
                maxWidth={!md ? "576px" : maxContentWidthDesktop}
                p={2}
            >
                <Box marginBottom={md ? "26px" : "10px"}>
                    <Typography
                        variant={!md ? "subtitle2" : "h6"}
                        fontWeight={600}
                        lineHeight={"24.2px"}
                        gutterBottom
                        color="#2B333B"
                    >
                        Delete
                    </Typography>
                    <Typography variant="subtitle2" lineHeight={"16.94px"} color="#2B333B">
                        Bulk delete emails to clean up your inbox
                    </Typography>
                </Box>
                <Box marginBottom={"20px"}>
                    <FeatureDetailedInfoCard title={featureTitle} description={featureDescription} />
                </Box>
                {!subscription ? (
                    <BuySubscription title="You have not subscribed to any plan yet. Please subscribe to use Delete Emails." />
                ) : md ? (
                    <DeleteEmailsTable />
                ) : (
                    <DeleteEmailsCardList />
                )}
            </Box>
        </Box>
    );
}
