import { getFeatureFlags, createCheckoutSession } from "services/applications";
import { useMutation, useQuery } from "@tanstack/react-query";
import { applicationKeys } from "queryKeyFactory";

export function useFeatureFlags(enabled) {
    return useQuery({
        queryKey: applicationKeys.featureFlags(),
        queryFn: getFeatureFlags,
        enabled: enabled,
    });
}

export function useCreateCheckoutSession() {
    return useMutation({
        mutationFn: createCheckoutSession,
    });
}
