import { backendServerAxiosInstance } from ".";
import { OrderDirection } from "types/maintypes";
import { formatOrderingString } from "utils";

export const getWorkflow = async (workflowId: number) => {
    const response = await backendServerAxiosInstance.get(`/jarvis/workflow/${workflowId}/`);
    return response.data;
};

export const createWorkflow = async (workflowParams: object) => {
    const response = await backendServerAxiosInstance.post("/jarvis/workflow/", workflowParams);
    return response.data;
};

export const updateWorkflow = async (workflowId: number, workflowParams: object) => {
    const response = await backendServerAxiosInstance.patch(`/jarvis/workflow/${workflowId}/`, workflowParams);
    return response.data;
};

export const listWorkflows = async (sortField: string, sortOrder: OrderDirection) => {
    const orderingString = formatOrderingString(sortField, sortOrder);
    const response = await backendServerAxiosInstance.get(`/jarvis/workflow?${orderingString}`);
    return response.data;
};

export const listComponentDefinitions = async () => {
    const response = await backendServerAxiosInstance.get("/jarvis/workflow-component-def");
    return response.data;
};

export const checkGoogleAuth = async () => {
    const response = await backendServerAxiosInstance.get("/jarvis/google-auth/check");
    return response.data;
};
