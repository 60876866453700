import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { userKeys } from "queryKeyFactory";
import { getUser, logoutUser } from "services/accounts";

export function useUser() {
    return useQuery({
        queryKey: userKeys.details(),
        queryFn: getUser,
    });
}

export function useLogoutUser() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: logoutUser,
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
}
