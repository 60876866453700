import { useQuery } from "@tanstack/react-query";
import { paymentKeys } from "queryKeyFactory";
import { getLatestSubscription, getPricings } from "services/payments/queries";

export function usePricings() {
    return useQuery({
        queryKey: paymentKeys.pricings(),
        queryFn: getPricings,
    });
}

export function useLatestSubscription() {
    return useQuery({
        queryKey: paymentKeys.latestSubscription(),
        queryFn: getLatestSubscription,
    });
}
