import axios from "axios";

import { getCookie } from "utils/cookies";

export const backendServerAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_BASE_URL,
    withCredentials: true,
});

backendServerAxiosInstance.interceptors.request.use(
    (config: any) => {
        const csrfToken = getCookie("csrftoken");
        if (csrfToken) {
            config.headers["X-CSRFToken"] = csrfToken;
        }
        return config;
    },
    (error: Error) => {
        // Handle request error
        return Promise.reject(error);
    },
);
