import { useQuery } from "@tanstack/react-query";
import { paymentKeys } from "queryKeyFactory";
import { getInvoices } from "services/payments/paginatedQueries";

export function useInvoices() {
    return useQuery({
        queryKey: paymentKeys.invoices(),
        queryFn: getInvoices,
    });
}
