import PaymentsIcon from "@mui/icons-material/Payments";
import { Avatar, Button, Menu, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Loader from "components/Common/Loader";
import ContactSupportMenu from "components/Common/Support";
import { useLogoutUser, useUser } from "hooks/api/accounts";
import { useMailBot } from "hooks/api/mailbot/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useNavigate } from "react-router-dom";

export function ProfileSection({ setOpen }) {
    const { data: userMailBotProfile, isPending } = useMailBot();
    const { data: user, isPending: isUserPending } = useUser();
    const logoutUser = useLogoutUser();
    const navigate = useNavigate();

    const handleLogout = () => {
        logoutUser.mutate(undefined, {
            onSuccess: () => {
                navigate("/");
            },
        });
    };

    if (logoutUser.isPending) {
        return <Loader />;
    }

    return (
        <Box>
            <PopupState variant="popover" popupId="popup-menu">
                {(popupState) => (
                    <>
                        <Button
                            {...bindTrigger(popupState)}
                            sx={{ display: "flex", gap: "10px", padding: 0 }}
                            data-testid={`sidebar-profile-button`}
                        >
                            <Box>
                                {!isPending && (
                                    <Avatar
                                        src={userMailBotProfile.user_picture}
                                        sx={{
                                            width: 35,
                                            height: 35,
                                        }}
                                        imgProps={{
                                            referrerPolicy: "no-referrer",
                                        }}
                                    />
                                )}
                            </Box>
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems="flex-start"
                                justifyContent="center"
                            >
                                {!isUserPending && (
                                    <Typography color="#FFFFFF" fontWeight={500} noWrap>
                                        {user.first_name} {user.last_name}
                                    </Typography>
                                )}
                                <Typography variant="subtitle2" noWrap color="#AEB9E1">
                                    {user.email}
                                </Typography>
                            </Box>
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem
                                data-testid={`sidebar-account-settings`}
                                onClick={() => {
                                    popupState.close();
                                    setOpen(false);
                                    navigate("/profile");
                                }}
                            >
                                Account Settings
                            </MenuItem>
                            <MenuItem
                                data-testid={`sidebar-logout`}
                                onClick={() => {
                                    popupState.close();
                                    setOpen(false);
                                    handleLogout();
                                }}
                            >
                                Logout
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </PopupState>
        </Box>
    );
}

export default function SideBarFooter({ setOpen }) {
    const { md } = useCustomMediaQuery();
    const navigate = useNavigate();
    return (
        <Box p={2} width={"100%"} display={"flex"} flexDirection={"column"} alignItems="flex-start" gap={"16px"}>
            <Box
                component={"button"}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"10px"}
                data-testid="sidebar-subscription"
                onClick={() => {
                    setOpen(false);
                    navigate("/subscription");
                }}
            >
                <PaymentsIcon color="primary" fontSize="large" />
                <Typography color="#AEB9E1">Subscription</Typography>
            </Box>
            <ContactSupportMenu fullScreen={!md} />
        </Box>
    );
}
