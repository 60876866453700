import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import DataCard from "components/Common/Data/DataCard";
import Loader from "components/Common/Loader";
import { UserAction, UserActionReason } from "defines";
import { useDeleteHistoricalEmails } from "hooks/api/mailbot/mutations";
import { useMailBotSenderProfiles } from "hooks/api/mailbot/paginatedQueries";
import { SnackbarMessageContext } from "layouts/AppLayout";
import moment from "moment-timezone";
import { useContext } from "react";
import { getDisplayNumber } from "utils/formatter";
import FuturePreferenceButton from "./FuturePreferenceButton";

function CardFooter({ profile, handleDeleteEmails }) {
    const senderBlockedByEmailZap = !!profile.blocked_by_emailzap;
    const senderBlockedByUser = profile.user_training === "zapped";
    if (
        profile.user_action_reason !== UserActionReason.MARKED_DELETED &&
        (senderBlockedByEmailZap || senderBlockedByUser)
    ) {
        return (
            <Box marginTop={"20px"}>
                <FuturePreferenceButton profile={profile} handleDeleteEmails={handleDeleteEmails} />
            </Box>
        );
    } else {
        return (
            <Box
                marginTop={"20px"}
                display={"flex"}
                justifyContent={"flex-end"}
                width={"100%"}
                sx={
                    profile.user_action_reason === UserActionReason.MARKED_DELETED
                        ? {
                              background: "#F9FAFB",
                          }
                        : {}
                }
            >
                <FuturePreferenceButton profile={profile} handleDeleteEmails={handleDeleteEmails} />
            </Box>
        );
    }
}

function DeleteEmailsCard({ profile }) {
    const { setSuccessMessage, setSuccessMessageTitle } = useContext(SnackbarMessageContext);
    const deleteEmailsMutation = useDeleteHistoricalEmails();
    const handleDeleteEmails = ({ userAction }) => {
        deleteEmailsMutation.mutate(
            {
                senderProfileId: profile.id,
                action: userAction,
            },
            {
                onSuccess: () => {
                    let label;
                    if (userAction === UserAction.MOVE_TO_TRASH) {
                        label = "moved to trash";
                    } else if (userAction === UserAction.ARCHIVE) {
                        label = "archived";
                    } else {
                        label = "moved to inbox";
                    }
                    if (profile.user_action_reason !== UserActionReason.MARKED_DELETED) {
                        setSuccessMessageTitle("Deleted successfully");
                        setSuccessMessage(`Email deleted successfully and all future emails will be ${label}`);
                    }
                },
            },
        );
    };
    return (
        <Paper
            variant="outlined"
            sx={{
                width: "100%",
                boxShadow: "0px 1px 1px 0px #0000001A",
                border: 0,
                borderRadius: 0,
                background: "white",
            }}
        >
            <Box p={1}>
                <Box mb={1} display={"flex"} alignItems={"center"} gap={"8px"} width={"90%"}>
                    <MailOutlineIcon
                        sx={{
                            color: "#111827",
                        }}
                    />
                    <Typography color="##111827" variant="subtitle2" fontWeight={500} lineHeight={"16.94px"} noWrap>
                        {profile.sender_email}
                    </Typography>
                </Box>
                {profile.user_action_reason !== UserActionReason.MARKED_DELETED && (
                    <Box display={"flex"} gap={"8px"}>
                        <Typography color="#616265" variant="body2">
                            Mails: <strong>{getDisplayNumber(profile.inbox_count)}</strong>
                        </Typography>
                        <Typography color="#616265" variant="body2">
                            Last mail: <strong>{moment(profile.recent_timestamp).format("MMM DD, YYYY")}</strong>
                        </Typography>
                    </Box>
                )}
                {!deleteEmailsMutation.isPending && (
                    <CardFooter profile={profile} handleDeleteEmails={handleDeleteEmails} />
                )}
                {deleteEmailsMutation.isPending && profile.user_action_reason !== UserActionReason.MARKED_DELETED && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        gap={"8px"}
                        height={"100%"}
                        marginTop={"20px"}
                    >
                        <Loader size={20} fullScreen={false} />
                        <Typography fontSize={"14px"} lineHeight={"16.94px"} color="#000000" fontWeight={500}>
                            Deleting mails...
                        </Typography>
                    </Box>
                )}
            </Box>
        </Paper>
    );
}

export default function DeleteEmailsCardList() {
    return (
        <DataCard
            useData={useMailBotSenderProfiles}
            pageSize={20}
            ordering="-scanned_count,sender_email"
            renderCard={({ profile }) => <DeleteEmailsCard profile={profile} />}
        />
    );
}
