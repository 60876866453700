import { backendServerAxiosInstance } from "services";

/**
 * Get all invoices
 *
 * TODO: Add pagination as the number of invoices grows
 */
export const getInvoices = async () => {
    const response = await backendServerAxiosInstance.get(`/payments/invoices/`);
    return response.data;
};
