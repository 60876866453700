import { Button, Typography } from "@mui/material";
import ZapSenderIcon from "components/Common/Icons/ZapSenderIcon";
import Loader from "components/Common/Loader";
import { useUpdateUserTrainingStatus } from "hooks/api/mailbot/mutations";
import { useIsMailBotActive } from "hooks/api/mailbot/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { SnackbarMessageContext } from "layouts/AppLayout";
import { useContext } from "react";

export default function ZapMailButton({ profile }) {
    const { md } = useCustomMediaQuery();
    const updateTrainingStatusMutation = useUpdateUserTrainingStatus();
    const { data: mailBotEnabled, isPending: mailBotEnabledPending } = useIsMailBotActive();
    const { setSuccessMessage, setSuccessMessageTitle } = useContext(SnackbarMessageContext);
    if (mailBotEnabledPending) {
        return null;
    }
    const disabled = !mailBotEnabled;
    return (
        <>
            {updateTrainingStatusMutation.isPending && <Loader />}
            <Button
                variant="contained"
                sx={{
                    background: "#FFAE02",
                    borderRadius: "4px",
                    height: md ? "40px" : "30px",
                }}
                data-testid={`zap-${profile.sender_email}`}
                fullWidth
                disableElevation
                disabled={disabled}
                onClick={() => {
                    updateTrainingStatusMutation.mutate(
                        { profiles: [profile], trainingLabel: "zapped" },
                        {
                            onSuccess: () => {
                                setSuccessMessageTitle("Zapped by you");
                                setSuccessMessage(
                                    'All future emails from this sender will be automatically moved to the "Zapped" label',
                                );
                            },
                        },
                    );
                }}
                startIcon={<ZapSenderIcon size={md ? 30 : 20} />}
            >
                <Typography fontWeight={500} color="#000000" lineHeight={"16.94px"} variant={md ? "body2" : "caption"}>
                    Zap sender
                </Typography>
            </Button>
        </>
    );
}
