import { Box, Tab, Tabs } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

function JarvisLayout() {
    const navigate = useNavigate();
    return (
        <Box>
            <Tabs
                variant="fullWidth"
                sx={{
                    marginBottom: "20px",
                }}
            >
                <Tab label="Create workflow" onClick={() => navigate("/workflows/create")} />
                <Tab label="All workflows" onClick={() => navigate("/workflows/list")} />
            </Tabs>
            <Box p={2}>
                <Outlet />
            </Box>
        </Box>
    );
}

export default JarvisLayout;
