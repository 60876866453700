import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import posthog, { PostHog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const posthogAPIKey: string = process.env.REACT_APP_POSTHOG_API_KEY!;
const CURRENT_ENV: string = process.env.REACT_APP_NODE_ENV!;

posthog.init(posthogAPIKey, {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
    capture_pageview: false,
    session_idle_timeout_seconds: 30,
    disable_session_recording: true,
    loaded: function (posthogInstance: PostHog) {
        if (CURRENT_ENV === "prod") {
            // Only record sessions in production
            posthogInstance.startSessionRecording();
        }
    },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        },
    },
});

if (CURRENT_ENV === "prod") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.info = () => {};
}

root.render(
    <React.StrictMode>
        <PostHogProvider client={posthog}>
            <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </PostHogProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
