import { backendServerAxiosInstance } from "services";

export const getStatistics = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/statistics/");
    return response.data;
};

export const getIsMailBotActive = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/mailbot-profile/is-active/`);
    return response.data;
};

export const getCanMailBotEnabled = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/mailbot-profile/can-enable/`);
    return response.data;
};

export const getCanMailBotDisabled = async () => {
    const response = await backendServerAxiosInstance.get(`/mailbot/mailbot-profile/can-disable/`);
    return response.data;
};

export const getMailBotPreferences = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/preferences/");
    return response.data;
};

export const getMailSecondaryProfiles = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/secondary-profiles/");
    return response.data;
};

export const getHistoricalEmailsCount = async ({ senderEmail }) => {
    const response = await backendServerAxiosInstance.get(
        `/mailbot/sender-profiles/bulk-delete-count/?sender_email=${senderEmail}`,
    );
    return response.data;
};
