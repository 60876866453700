import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar,
    Box,
    Button,
    createTheme,
    CssBaseline,
    IconButton,
    Slide,
    ThemeProvider,
    Toolbar,
    useMediaQuery,
    useScrollTrigger,
} from "@mui/material";
import AccessRevokedDialog from "components/Common/Dialog/AccessRevokedDialog";
import InsufficientScopesDialog from "components/Common/Dialog/InsufficientScopesDialog";
import NewUIDialog from "components/Common/Dialog/NewUIDialog";
import OfflineStatusDialog from "components/Common/Dialog/OfflineStatusDialog";
import EmailZapIcon from "components/Common/Icons/EmailZapIcon";
import SideBar from "components/Common/Sidebar";
import SnackBar from "components/Common/Snackbar";
import { createContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const theme = createTheme({
    palette: {
        primary: {
            dark: "#a7822e",
            light: "#f2c767",
            main: "#EFBA42",
            contrastText: "#000000",
        },
        background: {
            default: "#F9FAFB",
        },
        text: {
            primary: "#1A1A1A",
            secondary: "#808080",
        },
    },
    typography: {
        fontFamily: "Inter",
        button: {
            textTransform: "none", // This is used to disable the uppercase transformation of the button text in MUI
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 1024,
            lg: 1300,
            xl: 1736,
        },
    },
});

export const SnackbarMessageContext = createContext({
    setSuccessMessage: (message: string) => {},
    setErrorMessage: (message: string) => {},
    setSuccessMessageTitle: (message: string) => {},
});

function LoggedInAppContent() {
    const md = useMediaQuery("(min-width:1400px)");
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState("");
    const [successMessageTitle, setSuccessMessageTitle] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const trigger = useScrollTrigger(); // true when scrolling down, false when scrolling up

    return (
        <SnackbarMessageContext.Provider
            value={{
                setSuccessMessage,
                setErrorMessage,
                setSuccessMessageTitle,
            }}
        >
            <Box display={"flex"} height={"100%"} width={"100%"}>
                <CssBaseline /> {/* CssBaseline is used to reset the default browser styling */}
                <SideBar open={open} setOpen={setOpen} />
                <InsufficientScopesDialog />
                <OfflineStatusDialog />
                <AccessRevokedDialog />
                <NewUIDialog />
                <SnackBar
                    open={!!successMessage}
                    handleClose={() => setSuccessMessage("")}
                    severity={"success"}
                    message={successMessage}
                    title={successMessageTitle}
                    autoHideDuration={3000}
                />
                <SnackBar
                    open={!!errorMessage}
                    handleClose={() => setErrorMessage("")}
                    severity={"error"}
                    message={errorMessage}
                    autoHideDuration={3000}
                />
                <Box component="main" width={"100%"}>
                    {!md && (
                        <Slide appear={false} direction="down" in={!trigger}>
                            <AppBar
                                position="sticky"
                                sx={{
                                    backgroundColor: "white",
                                    zIndex: (theme) => theme.zIndex.drawer - 1,
                                }}
                            >
                                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <IconButton aria-label="menu" onClick={() => setOpen(true)}>
                                        <MenuIcon />
                                    </IconButton>
                                    <Button
                                        onClick={() => {
                                            navigate("/");
                                        }}
                                        startIcon={<EmailZapIcon darkBackground={false} />}
                                    />
                                </Toolbar>
                            </AppBar>
                        </Slide>
                    )}
                    <Outlet />
                </Box>
            </Box>
        </SnackbarMessageContext.Provider>
    );
}

export default function AppLayout({ isUserLoggedIn }) {
    return (
        <ThemeProvider theme={theme}>
            {isUserLoggedIn && <LoggedInAppContent />}
            {!isUserLoggedIn && <Outlet />}
        </ThemeProvider>
    );
}
