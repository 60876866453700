import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import ZappedText from "components/AutoCleaner/Zapped";
import DataTable from "components/Common/Data/DataTable";
import EmailDomainImage from "components/Common/EmailDomainImage";
import UnsubscribeIcon from "components/Common/Icons/UnsubscribeIcon";
import { UserActionReason } from "defines";
import { useSenderUnsubscribeDetails } from "hooks/api/mailbot/paginatedQueries";
import moment from "moment-timezone";
import { getDisplayNumber } from "utils/formatter";
import UnsubscribeButton from "./UnsubscribeButton";

const columns: GridColDef[] = [
    {
        field: "sender_email",
        headerName: "Sender's Email",
        flex: 2,
        sortingOrder: ["asc", "desc"],
        headerClassName: "bg-white",
        renderCell: (params) => {
            return (
                <Box height={"100%"} display={"flex"} alignItems={"center"} gap={"16px"}>
                    <EmailDomainImage
                        email={params.row.sender_email}
                        domain={params.row.sender_domain}
                        name={params.row.sender_name}
                    />
                    <Tooltip title={params.value}>
                        <Typography noWrap>{params.value}</Typography>
                    </Tooltip>
                </Box>
            );
        },
    },
    {
        field: "total_count",
        headerName: "Emails Received",
        flex: 1,
        sortingOrder: ["asc", "desc"],
        headerClassName: "bg-white",
        valueFormatter: (value) => getDisplayNumber(value),
    },
    {
        field: "recent_timestamp",
        headerName: "Last Email Received",
        flex: 1,
        sortingOrder: ["asc", "desc"],
        headerClassName: "bg-white",
        valueFormatter: (value: any) => {
            return moment(value).format("MMM DD, YYYY");
        },
    },
    {
        field: "custom_rules",
        headerName: "",
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "bg-white",
        resizable: false,
        align: "center",
        renderCell: (params) => {
            const senderBlockedByEmailZap = !!params.row.blocked_by_emailzap;
            const senderBlockedByUser = params.row.user_training === "zapped";
            if (params.row.user_action_reason === UserActionReason.UNSUBSCRIBED) {
                return (
                    <Box display={"flex"} justifyContent={"flex-end"} alignItems="center" height={"100%"} gap={"8px"}>
                        <UnsubscribeIcon size={20} color="#1B130199" />
                        <Typography fontSize={"14px"} color="#1B130199">
                            Unsubscribed
                        </Typography>
                    </Box>
                );
            } else {
                return senderBlockedByEmailZap || senderBlockedByUser ? (
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}>
                        <ZappedText title={senderBlockedByEmailZap ? "Zapped by EmailZap" : "Zapped by you"} />
                        <UnsubscribeButton profile={params.row} />
                    </Box>
                ) : (
                    <Box display={"flex"} justifyContent={"flex-end"} height={"100%"} alignItems={"center"}>
                        <UnsubscribeButton profile={params.row} />
                    </Box>
                );
            }
        },
    },
];

export default function UnsubscribeTable() {
    return (
        <DataTable
            tabs={[
                {
                    label: "Subscriptions",
                    condition: [],
                    filters: [],
                },
                {
                    label: "Unsubscribed",
                    condition: [
                        {
                            user_action_reason: UserActionReason.UNSUBSCRIBED,
                        },
                    ],
                    filters: [],
                    badgeContent: (data) => {
                        return data.unsubscribed_count;
                    },
                },
            ]}
            defaultOrdering={[{ field: "total_count", sort: "desc" }]}
            columns={columns}
            useData={useSenderUnsubscribeDetails}
            pageSize={20}
        />
    );
}
