export default function EditIcon({ size = 16 }) {
    return (
        <svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0102 4.12224L12.4032 2.10921C12.4711 1.75937 12.4007 1.40368 12.2034 1.10729C12.0061 0.81089 11.7051 0.609672 11.3577 0.540376L8.75454 0.0252129H8.75371C8.04406 -0.116725 7.33856 0.355018 7.19599 1.07807L4.96333 12.5142C4.93266 12.6711 4.95919 12.8323 5.03629 12.9709L6.53847 15.6593C6.63463 15.8322 6.8021 15.9524 6.99525 15.9891C7.03504 15.9967 7.07649 16 7.11629 16C7.26883 16 7.41888 15.9466 7.53826 15.8472L9.95896 13.835C10.0767 13.7365 10.1579 13.6004 10.1878 13.4493L12.0083 4.12644C12.0083 4.1256 12.0083 4.1256 12.0091 4.12477V4.12393L12.0102 4.12224ZM8.49757 1.33695L11.1016 1.85212L10.8363 3.21056L8.23314 2.6954L8.49842 1.33695H8.49757ZM9.23208 11.4246L7.8725 11.1565L6.63891 10.861L7.97695 4.00624L10.5802 4.5214L9.23208 11.4246ZM7.30709 14.3075L6.31392 12.53L6.38356 12.1718L7.59227 12.4607L8.97673 12.7345L8.93362 12.9549L7.30709 14.3075ZM4.62515 5.70351L2.15967 7.92366L4.62345 10.1271C4.89703 10.3726 4.92189 10.7943 4.67899 11.0706C4.54718 11.2192 4.36562 11.2952 4.18325 11.2952C4.02656 11.2952 3.86904 11.2401 3.74304 11.1266L0.723011 8.42464C0.582078 8.29773 0.5 8.11655 0.5 7.92619C0.5 7.73583 0.580416 7.55381 0.720528 7.42691L3.74056 4.70745C4.01414 4.46116 4.43279 4.48535 4.67736 4.75922C4.92191 5.03476 4.89789 5.45639 4.62596 5.7027L4.62515 5.70351ZM16.4992 7.92601C16.4992 8.11637 16.4179 8.29756 16.2762 8.42446L13.2561 11.1264C13.1301 11.2391 12.9726 11.295 12.8159 11.295C12.6336 11.295 12.4512 11.2191 12.3202 11.0704C12.0773 10.7949 12.1022 10.3724 12.3757 10.127L14.8395 7.92348L12.374 5.70333C12.1013 5.45703 12.0781 5.03538 12.3226 4.75985C12.5664 4.48515 12.9859 4.46178 13.2594 4.70808L16.2795 7.42753C16.4204 7.55444 16.5 7.73646 16.5 7.92682L16.4992 7.92601Z"
                fill="#1B1301"
            />
        </svg>
    );
}
