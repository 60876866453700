import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV!;

export default function useGoogleAnalytics() {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (CURRENT_ENV === "prod") {
            ReactGA.initialize("G-6FTN7T6QW4");
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
}
