import {
    Box,
    Button,
    Dialog,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import Loader from "components/Common/Loader";
import { UserAction, UserActionReason } from "defines";
import { useHistoricalEmailsCount } from "hooks/api/mailbot/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

export default function FutureDeletePreference({
    userAction,
    setUserAction,
    profile,
    open,
    setOpen,
    handleDeleteEmails,
}) {
    const { md } = useCustomMediaQuery();
    let showHeader = profile.user_action_reason !== UserActionReason.MARKED_DELETED;
    const { data: historicalEmailsCount, isPending: historicalEmailsCountPending } = useHistoricalEmailsCount({
        senderEmail: profile.sender_email,
        enabled: showHeader,
    });
    const handleConfirm = () => {
        handleDeleteEmails({ userAction });
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
                style: {
                    background: "#EFBA42",
                },
            }}
        >
            {showHeader &&
                (historicalEmailsCountPending ? (
                    <Box p={2} display={"flex"} flexDirection={"row"} justifyContent={"center"} gap={"10px"}>
                        <Typography noWrap fontSize={md ? "14px" : "12px"}>
                            Fetching number of emails
                        </Typography>
                        <Loader fullScreen={false} size={20} color="inherit" />
                    </Box>
                ) : (
                    <Box p={2}>
                        <Typography noWrap fontSize={md ? "14px" : "12px"}>
                            EmailZap will delete <strong>{historicalEmailsCount.count}</strong>{" "}
                            {historicalEmailsCount.count > 1 ? "emails" : "email"} from{" "}
                            <strong>{profile.sender_email}</strong>
                        </Typography>
                    </Box>
                ))}
            <Divider />
            <Box p={2}>
                <Typography fontWeight={500} fontSize={"14px"} lineHeight={"16.94px"}>
                    How would you like EmailZap to handle future emails from this sender?
                </Typography>
                <FormControl disabled={showHeader && historicalEmailsCountPending}>
                    <FormLabel id="future-delete-label" />
                    <RadioGroup
                        row={md}
                        aria-labelledby="future-delete-label"
                        name="row-radio-buttons-group"
                        value={userAction}
                        onChange={(e) => setUserAction(e.target.value)}
                    >
                        <FormControlLabel
                            value={UserAction.MOVE_TO_TRASH}
                            control={<Radio color="default" />}
                            label={
                                <Typography fontWeight={userAction === UserAction.MOVE_TO_TRASH ? 600 : 500}>
                                    Auto Delete
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            value={UserAction.MOVE_TO_INBOX}
                            control={<Radio color="default" />}
                            label={
                                <Typography fontWeight={userAction === UserAction.MOVE_TO_INBOX ? 600 : 500}>
                                    Move to Inbox
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            value={UserAction.ARCHIVE}
                            control={<Radio color="default" />}
                            label={
                                <Typography fontWeight={userAction === UserAction.ARCHIVE ? 600 : 500}>
                                    Archive
                                </Typography>
                            }
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box
                p={2}
                display={"flex"}
                width={"100%"}
                justifyContent="space-around"
                flexDirection={md ? "row" : "column"}
                height={"100%"}
            >
                <Button
                    variant="contained"
                    disableElevation
                    sx={{
                        background: "#1F2337",
                        color: "#FFFFFF",
                        width: md ? "175px" : "100%",
                        marginBottom: md ? "0" : "24px",
                    }}
                    disabled={showHeader && historicalEmailsCountPending}
                    onClick={handleConfirm}
                >
                    Confirm
                </Button>
                <Button
                    disableElevation
                    disabled={showHeader && historicalEmailsCountPending}
                    sx={{
                        color: "#1F2337",
                        width: md ? "175px" : "100%",
                        fontWeight: 600,
                        fontSize: "14px",
                    }}
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
            </Box>
        </Dialog>
    );
}
