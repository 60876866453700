import Loader from "components/Common/Loader";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getWorkflow, listComponentDefinitions, updateWorkflow } from "services/jarvis";
import { IJsonWorkflowSection, IWorkflow, WorkflowState } from "types/maintypes";
import { deepCopy } from "utils";
import { validateWorkflow } from "utils/workflow";
import WorkflowSection from "components/Jarvis/WorkflowSection";
import SnackBar from "components/Common/Snackbar";

const sections: Array<IJsonWorkflowSection> = [
    {
        name: "Workflow Trigger",
        key: "trigger",
    },
    {
        name: "Constraints",
        key: "constraints",
    },
    {
        name: "Actions",
        key: "actions",
    },
];

function EditWorkflow() {
    const [id, setId] = useState<number | undefined>();
    const [workflow, setWorkflow] = useState<IWorkflow | undefined>();
    const [componentDefinitions, setComponentDefinitions] = useState<object[]>([]);
    const [loading, setLoading] = useState(true);
    const [isWorkflowValid, setIsWorkflowValid] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();
    const location = useLocation();

    const handleFailure = () => {
        setErrorMessage("Your workflow could not be fetched. Redirecting back to all workflows.");
        setTimeout(() => {
            navigate("/workflows/list");
        }, 5000);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const workflowId = params.get("id");
        if (!workflowId) {
            handleFailure();
            setLoading(false);
        } else {
            setId(parseInt(workflowId));
        }
    }, []);

    useEffect(() => {
        if (workflow?.json_workflow && componentDefinitions.length) {
            setIsWorkflowValid(validateWorkflow(workflow.json_workflow, componentDefinitions));
        }
    }, [workflow, componentDefinitions]);

    useEffect(() => {
        if (!id) {
            return;
        }
        // Fetch workflow details + workflow component definitions
        const promises = [getWorkflow(id), listComponentDefinitions()];
        // Wait for both promises to resolve
        Promise.all(promises)
            .then((results) => {
                setWorkflow(results[0]);
                setComponentDefinitions(results[1]);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                handleFailure();
            });
    }, [id]);

    /* Save updated steps of workflow, and activate it */
    const applyWorkflow = async () => {
        if (!workflow || !workflow?.id || !workflow?.json_workflow) {
            return;
        }
        try {
            await updateWorkflow(workflow.id, { json_workflow: workflow.json_workflow, is_active: true });
            navigate("/workflows/list");
        } catch (err) {
            setErrorMessage("Workflow could not be applied for your email.");
        }
    };

    /* Renders steps of workflow */
    const renderSection = (section: IJsonWorkflowSection) => {
        if (!workflow?.json_workflow) {
            return;
        }
        let workflowSectionJson = workflow.json_workflow[section.key];
        workflowSectionJson = Array.isArray(workflowSectionJson) ? workflowSectionJson : [workflowSectionJson];
        return (
            <div className="mb-5" key={section.key}>
                <div className="font-medium underline">{section.name}:</div>
                <WorkflowSection
                    sectionKey={section.key}
                    jsonObject={workflowSectionJson}
                    setJsonObject={setUpdatedWorkflow}
                    componentDefinitions={componentDefinitions}
                />
            </div>
        );
    };

    const setUpdatedWorkflow = (section: string, updatedEntities: object) => {
        if (!workflow) return;
        const updatedWorkflow = deepCopy(workflow);

        if (section === "trigger") {
            updatedEntities = updatedEntities[0];
        }
        if (updatedWorkflow.json_workflow) {
            updatedWorkflow.json_workflow[section] = updatedEntities;
        }
        setWorkflow(updatedWorkflow);
    };

    return (
        <>
            {loading && (
                <div className="flex flex-row justify-center">
                    <div className="mr-3">Fetching workflow</div>
                    <Loader size={4} fullScreen={false} />
                </div>
            )}
            {!loading && (
                <>
                    {workflow && workflow?.state !== WorkflowState.Configured && (
                        <div className="flex flex-row justify-center">
                            <div>Updating a workflow that hasn't been successfully configured is not allowed!</div>
                        </div>
                    )}
                    {workflow?.state === WorkflowState.Configured && (
                        <div className="border border-black p-6 rounded-lg">
                            <div className="font-medium pb-3">Edit workflow</div>
                            <form>
                                <div className="flex flex-col">
                                    <input
                                        type="text"
                                        placeholder="Workflow name"
                                        value={workflow?.name}
                                        className="w-full md:w-3/4 lg:w-1/2 p-2 rounded-md border border-gray-800 mb-3 bg-[#EFF0F6] cursor-not-allowed"
                                        disabled={true}
                                    />
                                    <input
                                        type="text"
                                        placeholder="When I get an email from x, move it to label Y"
                                        value={workflow?.user_query}
                                        className="w-full p-2 rounded-md border border-gray-800 mb-3 bg-[#EFF0F6] cursor-not-allowed"
                                        disabled={true}
                                    />
                                </div>
                            </form>
                            <div>
                                {workflow.json_workflow && Object.keys(workflow.json_workflow).length !== 0 && (
                                    <div className="flex mt-6 flex-col">
                                        <div className="mr-2 font-medium">Steps of your workflow</div>
                                        <div className="flex flex-col border border-black rounded-md py-2 px-4 bg-[#EFF0F6]">
                                            {sections.map((section) => renderSection(section))}
                                            <div className="flex flex-row mt-4 mb-2">
                                                {/* TODO: Share and reuse components b/w workflow create and update flow*/}
                                                <button
                                                    type="submit"
                                                    className={`${
                                                        isWorkflowValid
                                                            ? "bg-indigo-600 hover:bg-indigo-500 cursor-pointer"
                                                            : "bg-indigo-300 cursor-default"
                                                    } rounded-md px-6 py-3 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                                    onClick={applyWorkflow}
                                                    disabled={!isWorkflowValid}
                                                >
                                                    Apply to your email
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
            <SnackBar
                open={successMessage.length > 0}
                handleClose={() => setSuccessMessage("")}
                severity="success"
                message={successMessage}
                autoHideDuration={5000}
            />
            <SnackBar
                open={errorMessage.length > 0}
                handleClose={() => setErrorMessage("")}
                severity="error"
                message={errorMessage}
                autoHideDuration={5000}
            />
        </>
    );
}

export default EditWorkflow;
