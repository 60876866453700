import { Backdrop, CircularProgress } from "@mui/material";

type LoaderProps = {
    fullScreen?: boolean;
    size?: number;
    color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit";
};

const Loader = ({ fullScreen = true, size = 30, color = "primary" }: LoaderProps) => {
    if (fullScreen) {
        return (
            <Backdrop open={true} style={{ zIndex: 9999 }}>
                <CircularProgress size={size} color={color} />
            </Backdrop>
        );
    } else {
        return <CircularProgress color={color} size={size} />;
    }
};

export default Loader;
