export default function DeleteIcon({ size = 14 }) {
    return (
        <svg width={size} height={size} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.8333 3.00065H9.16667V2.33398C9.16667 1.80355 8.95595 1.29484 8.58088 0.919771C8.20581 0.544698 7.6971 0.333984 7.16667 0.333984H5.83333C5.3029 0.333984 4.79419 0.544698 4.41912 0.919771C4.04405 1.29484 3.83333 1.80355 3.83333 2.33398V3.00065H1.16667C0.989856 3.00065 0.820286 3.07089 0.695262 3.19591C0.570238 3.32094 0.5 3.49051 0.5 3.66732C0.5 3.84413 0.570238 4.0137 0.695262 4.13872C0.820286 4.26375 0.989856 4.33398 1.16667 4.33398H1.83333V11.6673C1.83333 12.1978 2.04405 12.7065 2.41912 13.0815C2.79419 13.4566 3.3029 13.6673 3.83333 13.6673H9.16667C9.6971 13.6673 10.2058 13.4566 10.5809 13.0815C10.956 12.7065 11.1667 12.1978 11.1667 11.6673V4.33398H11.8333C12.0101 4.33398 12.1797 4.26375 12.3047 4.13872C12.4298 4.0137 12.5 3.84413 12.5 3.66732C12.5 3.49051 12.4298 3.32094 12.3047 3.19591C12.1797 3.07089 12.0101 3.00065 11.8333 3.00065ZM5.16667 2.33398C5.16667 2.15717 5.2369 1.9876 5.36193 1.86258C5.48695 1.73756 5.65652 1.66732 5.83333 1.66732H7.16667C7.34348 1.66732 7.51305 1.73756 7.63807 1.86258C7.7631 1.9876 7.83333 2.15717 7.83333 2.33398V3.00065H5.16667V2.33398ZM9.83333 11.6673C9.83333 11.8441 9.7631 12.0137 9.63807 12.1387C9.51305 12.2637 9.34348 12.334 9.16667 12.334H3.83333C3.65652 12.334 3.48695 12.2637 3.36193 12.1387C3.2369 12.0137 3.16667 11.8441 3.16667 11.6673V4.33398H9.83333V11.6673Z"
                fill="#0B0B0A"
            />
        </svg>
    );
}
