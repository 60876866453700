import { backendServerAxiosInstance } from "services";

export const getUser = async () => {
    const response = await backendServerAxiosInstance.get("/mailbot/users/me/");
    return response.data;
};

export const logoutUser = async () => {
    const response = await backendServerAxiosInstance.post("/accounts/user/me/logout/");
    return response.data;
};
