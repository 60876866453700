import { Box, Button, Card, Dialog, Divider, Link, Typography } from "@mui/material";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import EmailZapIcon from "../Icons/EmailZapIcon";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMarkNewUIViewed } from "hooks/api/mailbot/mutations";
import { useMailBotMetadata } from "hooks/api/mailbot/queries";
import Loader from "components/Common/Loader";
import { useUser } from "hooks/api/accounts";

export default function NewUIDialog() {
    const { md } = useCustomMediaQuery();
    const { data: mailbotMetadata, isPending: mailbotMetadataPending } = useMailBotMetadata();
    const { data: user, isPending: isUserPending } = useUser();
    const markNewUIViewedMutation = useMarkNewUIViewed();
    if (mailbotMetadataPending || isUserPending || markNewUIViewedMutation.isPending) {
        return <Loader />;
    }
    const dateJoined = new Date(user.date_joined);
    const dateNewUIReleased = new Date(Date.UTC(2024, 9, 17)); // Note: month is 0-indexed, so 9 is October
    return (
        <Dialog
            fullScreen={!md}
            open={mailbotMetadata?.new_ui_viewed !== true && dateJoined <= dateNewUIReleased}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: md ? "748px" : "100%",
                    },
                },
            }}
            PaperProps={{
                style: {
                    background: "linear-gradient(180deg, rgba(49, 19, 97, 0) 50%, #311361 100%), #081028",
                },
            }}
        >
            <Box p={md ? 4 : 2} display={"flex"} flexDirection={"column"} alignItems={"center"} height={"100%"}>
                <Box display={"flex"} justifyContent={"center"} width={"100%"} marginBottom={md ? "16px" : "0px"}>
                    <EmailZapIcon size={63} />
                </Box>
                <Box
                    sx={{
                        margin: "auto",
                        width: "100%",
                        maxWidth: md ? "100%" : "600px",
                    }}
                >
                    <Card
                        sx={{
                            background: "#EFBA42",
                            borderRadius: "16px",
                        }}
                    >
                        <Box>
                            <Box p={md ? 4 : 2}>
                                <Typography
                                    gutterBottom
                                    fontWeight={700}
                                    variant={md ? "h4" : "h6"}
                                    align={md ? "inherit" : "center"}
                                >
                                    All new experience
                                </Typography>
                                <Typography variant={md ? "body1" : "body2"} align={md ? "inherit" : "center"}>
                                    We are thrilled to introduce all new EmailZap experience.
                                </Typography>
                            </Box>
                            <Divider
                                sx={{
                                    background: "#0000004D",
                                }}
                            />
                            <Box p={md ? 4 : 2}>
                                <Box>
                                    <Typography
                                        gutterBottom
                                        variant={md ? "subtitle1" : "subtitle2"}
                                        align={md ? "inherit" : "center"}
                                    >
                                        Write to us if you encounter any issues using EmailZap
                                    </Typography>
                                    <Link
                                        href="mailto:support@emailzap.co"
                                        fontWeight={700}
                                        display={"flex"}
                                        justifyContent={md ? "flex-start" : "center"}
                                        sx={{
                                            color: "#1C1D31",
                                            fontSize: md ? "16px" : "14px",
                                        }}
                                    >
                                        support@emailzap.co
                                    </Link>
                                </Box>
                                <Box marginTop={"110px"}>
                                    <Button
                                        variant="contained"
                                        disableElevation
                                        disableRipple
                                        fullWidth
                                        data-testid="new-ui-viewed-continue"
                                        sx={{
                                            background: "#1F2337",
                                            color: "#FFE500",
                                            height: "60px",
                                            borderRadius: "8px",
                                        }}
                                        endIcon={
                                            <ArrowForwardIcon
                                                sx={{
                                                    color: "#FFE500",
                                                }}
                                            />
                                        }
                                        onClick={() => {
                                            markNewUIViewedMutation.mutate();
                                        }}
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </Dialog>
    );
}
