import { APPLICATION_TAGS } from "defines";
import { useUser } from "./api/accounts";
import { useFeatureFlags } from "./api/applications";

export function useIsUserLoggedIn() {
    const { data, isPending, error } = useUser();
    if (isPending) {
        return { isPending: true, loggedIn: false };
    }
    return { isPending: false, loggedIn: !error && !!data };
}

export function useIsMailBotFeatureEnabled(feature: string) {
    const { isPending: userPending, loggedIn } = useIsUserLoggedIn();
    const { data: featureFlags, isPending } = useFeatureFlags(!userPending && loggedIn);
    if (isPending) {
        return { isPending: true, enabled: false };
    }
    if (featureFlags[APPLICATION_TAGS.EMAILZAP] && featureFlags[APPLICATION_TAGS.EMAILZAP][feature]) {
        return { isPending: false, enabled: true };
    } else {
        return { isPending: false, enabled: false };
    }
}
