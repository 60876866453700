import { backendServerAxiosInstance } from "services";

export async function getFeatureFlags() {
    const response = await backendServerAxiosInstance.get("applications/feature-flags/get/");
    return response.data;
}

export async function createCheckoutSession({ data, queryParams = {} }) {
    const response = await backendServerAxiosInstance.post("/applications/stripe/create-checkout-session/", data, {
        params: queryParams,
    });
    return response.data;
}
