import { Box, Button, Dialog, Typography } from "@mui/material";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

export default function UnsubscribeConfirmDialog({ open, setOpen, handleUnsubscribe }) {
    const { md } = useCustomMediaQuery();
    const handleConfirm = () => {
        handleUnsubscribe();
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
                style: {
                    background: "#EFBA42",
                },
            }}
        >
            <Box p={2}>
                <Typography fontWeight={500} fontSize={"14px"} lineHeight={"16.94px"}>
                    Are you sure you want to unsubscribe from emails sent by this sender?
                </Typography>
            </Box>
            <Box
                p={2}
                display={"flex"}
                width={"100%"}
                justifyContent="space-around"
                flexDirection={md ? "row" : "column"}
                height={"100%"}
            >
                <Button
                    variant="contained"
                    disableElevation
                    sx={{
                        background: "#1F2337",
                        color: "#FFFFFF",
                        width: md ? "175px" : "100%",
                        marginBottom: md ? "0" : "24px",
                    }}
                    onClick={handleConfirm}
                >
                    Continue
                </Button>
                <Button
                    disableElevation
                    sx={{
                        color: "#1F2337",
                        width: md ? "175px" : "100%",
                        fontWeight: 600,
                        fontSize: "14px",
                    }}
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
            </Box>
        </Dialog>
    );
}
