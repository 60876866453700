import { backendServerAxiosInstance } from "services";

export const cancelSubscription = async ({ id, reason, reasonText }) => {
    const response = await backendServerAxiosInstance.post(`/payments/subscriptions/${id}/cancel/`, {
        reason,
        reason_text: reasonText,
    });
    return response.data;
};

export const renewSubscription = async (id: string) => {
    const response = await backendServerAxiosInstance.post(`/payments/subscriptions/${id}/renew/`);
    return response.data;
};

export const upgradeSubscription = async ({ id, newPriceId }) => {
    const response = await backendServerAxiosInstance.post(`/payments/subscriptions/${id}/upgrade/`, {
        price_id: newPriceId,
    });
    return response.data;
};

export const createCustomerPortalSession = async () => {
    const response = await backendServerAxiosInstance.post(`/payments/customer-portal/session/`);
    return response.data;
};
