export default function MailIcon({ size = 20 }) {
    return (
        <svg width={1.64 * size} height={size} viewBox="0 0 228 139" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="228" height="139" rx="2" fill="black" />
            <path
                d="M110.904 88.307C113.035 89.9034 115.965 89.9034 118.096 88.307L205.574 22.8027C210.191 19.3452 207.746 12 201.977 12H27.0228C21.2544 12 18.8091 19.3452 23.4264 22.8027L110.904 88.307Z"
                fill="white"
            />
            <path
                d="M65.4498 77.2293C68.3357 74.8303 68.3357 70.4005 65.4498 68.0015L23.8356 33.4072C19.9263 30.1574 14 32.9375 14 38.0211L14 107.21C14 112.293 19.9263 115.073 23.8356 111.824L65.4498 77.2293Z"
                fill="white"
            />
            <path
                d="M162.55 68.0012C159.664 70.4002 159.664 74.8299 162.55 77.229L204.164 111.823C208.074 115.073 214 112.293 214 107.209L214 38.0208C214 32.9371 208.074 30.1571 204.164 33.4069L162.55 68.0012Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M118.262 105.971C116.066 107.739 112.934 107.739 110.738 105.971L76.4703 78.3867L27 126.999H201L151.98 78.8292L118.262 105.971Z"
                fill="white"
            />
        </svg>
    );
}
